import React from 'react';
import {Button, Modal} from "react-bootstrap";

interface IProps {
    confirmTitle?: string;
    confirmText?: string;
    confirmHint?: string;
    fnClose: (confirmAction: string) => void;
}
interface IState {
    internalShow: boolean;
}

export class ConfirmDialog extends React.Component<IProps, IState> {
    public state = {
        internalShow: true,
    }
    
    private setInternalShow = (newState: boolean): void => {
        this.setState({internalShow: newState});
    }
    
    
    render() {
        const { confirmTitle, confirmText, confirmHint, fnClose } = this.props;
        
        return (
            <Modal show={true} onHide={() => fnClose("")} keyboard={false}>
                {confirmTitle &&
                    <Modal.Header closeButton={true}>
                        <Modal.Title>
                                {confirmTitle}
                        </Modal.Title>
                    </Modal.Header>
                }
                <Modal.Body>
                    {confirmText &&
                        <p>
                            {confirmText}
                        </p>
                    }
                    {confirmHint &&
                        <p>
                            {confirmHint}
                        </p>
                    }
                    
                </Modal.Body>
                <Modal.Footer>
                    <Button variant={"primary"} onClick={() => fnClose("yes")}>Yes</Button>
                    <Button variant={"secondary"} onClick={() => fnClose("no")}>No</Button>
                </Modal.Footer>
            </Modal>
            
        )

    }
}
