import {actionCommands} from "../actionCommands";

export interface ISecurityToken {
    securityKey: string; // "GtG67-01022025-rm342n2qh-hLWPf"
    tokenPk: string;     // "aIjYx-01022025-rm342msm8-TLXBp"
    userName: string;    // "stefan"
    userPk: string;      // "5DxDP-01022025-q3wadrz46-SHdUH"
    validUntil: string;  // "2025-02-02 00:38:20"
}

const userLogin = (user: ISecurityToken) => {
    return {
        type: "USER_LOGIN",
        payload: user
    };
};

const userLogout = () => {
    return {
        type: "USER_LOGOUT"
    };
};

const adSetFullData = (entity: string, data: any[]) => {
    return {
        type: "AD_SETDATAFULL",
        payload: {entity, data}
    };
};

export default {
    userLogin, userLogout, adSetFullData
}