import React, {useEffect, useState} from 'react';
//import PropTypes from 'prop-types';

import {ServiceCall} from "../../../../sharedLib/ServiceCall";
import sl6ServicePath from "../../config/sl6Services.json";

import {IApiFeedback} from "../../../shared/typeDef/apiFeedback";
import {PornStarListing} from "./PornStarListing";
import {IPornStarItems} from "../../typeDef/pornStarData";

export const PornStar: React.FC<{

}> = (
    ) => {
    // state
    const [apiPornStars, setApiPornStars] = useState<IApiFeedback>();
    
    // use-effect
    useEffect(() => {
        loadAllPornstarListing();
    }, []);
    
    // functions
    function callbackLoadAllPornstarListing(serviceCall: ServiceCall) {
        const data = serviceCall.getResponseData();
        const apiPornStars = {
            state: 'loaded',
            error: serviceCall.getRequestConfigStatusCode() === 0 ? '' : 'Warning/Error',
            data: data,
            msgList: serviceCall.getResponseInfo(),
        }
        // neu mit Redux Daten laden
        setApiPornStars(apiPornStars)
    }
    function loadAllPornstarListing() {
        const pornstarListingService = new ServiceCall(sl6ServicePath.getAllPornstarListing);
        pornstarListingService.executePost({}, callbackLoadAllPornstarListing);
    }
    
    // other
    const pornStarData: IPornStarItems = apiPornStars && apiPornStars.data ? apiPornStars.data : [];
    
    return (
        <>
            {/*
                -- Brot-Krümel: Titel-Navigation ----------------------------------------------------------------------
*/}
            <div className={"titleNavLineBlock"}>
                all Porn Stars (FC)
            </div>
            {apiPornStars && apiPornStars.data &&
                <PornStarListing pornStarData={pornStarData} dataTest={"test"} />
            }
    
    
    
            </>
    )
}

