import React, {useState} from 'react';
//import PropTypes from 'prop-types';

import {IPornStarItems, IPornStarItem} from "../../typeDef/pornStarData";
import pornStarStyles from "./pornStar.module.css";
import {IFileItem} from "../../../../typeDef/fileItem";
import {getFirstImageFromFileList} from "../../../../sharedLib/fileLib";
import {getlocalHtdocsPath} from "../../config/getConfigData";
import appGenericStyles from "../../../../css/appGeneric.module.css";
import {InputText} from "../../../../component/input/InputText";
import {NatListComponent} from "../../../../component/natListComponent/NatListComponent";

const localHtDocsPath = getlocalHtdocsPath();

export const PornStarListing: React.FC<{
    pornStarData: IPornStarItems;
    dataTest: string;
}> = ({
          pornStarData,
          dataTest
      }
) => {
    // state
    const [psSearchField, setPsSearchField] = useState<string>("");
    
    // functions
    function getEvtTargetName(e: any): any {
        return "";
    }
    
    // other
    
    if (pornStarData) {
        return (
            <>
                <div className={appGenericStyles.divTableRow}>
                    <div className={appGenericStyles.divTableCell}>
                        Girl Name: <InputText name={"psSearchField"} value={psSearchField} fnChangeHandler={(what: string, name: string, newValue: any) => setPsSearchField(newValue)} width={"200px"} isEditable={true} />
                    </div>
                </div>
                <ul key={"ps_ul"} className={pornStarStyles.pornStarListUl}>
                {pornStarData.map((psItem: IPornStarItem) => {
                    const imgFile: IFileItem = getFirstImageFromFileList(psItem.fileItems);
                    const imgPath = imgFile ?  localHtDocsPath + "pornstar/pornstar_" + psItem.pornstarId + "/" + imgFile.filename : "";
                    const psItemStarNameLC = psItem.starName ? psItem.starName.toLowerCase() : "";
                    const psSearchFieldLC = psSearchField ? psSearchField.toLowerCase() : "";
                    if (psItemStarNameLC.includes(psSearchFieldLC)) {
                        const birthDate = new Date(psItem.starBirthdate);
                        const difference: number = Date.now() - birthDate.getTime();
                        const age = new Date(difference);
                        const starAge: number = Math.abs(age.getUTCFullYear() - 1970);
                        
                        return (
                            <li key={"li_"+ psItem.pornstarId}>
                                <div className={pornStarStyles.pornStarTitleDiv}>
                                    {psItem.starName}
                                </div>
                                <div className={pornStarStyles.pornStarListDiv}>
                                    <div>
                                        {imgPath !== "" &&
                                            <img className={pornStarStyles.pornStarListImg}
                                                 src={imgPath} alt={""}/>
                                        }
                                    </div>
                                </div>
                                <div>
                                    <NatListComponent natString={psItem.starCountry}/>
                                    {starAge > 0 &&
                                        <span>
                                            {` / Age: ${starAge}`}
                                        </span>
                                    }
                                </div>
                                <br />
                            </li>
                        )
                    }

                })}
                </ul>
            </>
        )
    }
    return null;
    
}

