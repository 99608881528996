import React, {useEffect, useState} from 'react';
//import PropTypes from 'prop-types';

import {ServiceCall} from "../../../../sharedLib/ServiceCall";
import slhServicePath from "../../config/slhServices.json";
import slhEntities from "../../config/slhEntities.json";

import {IApiFeedback} from "../../../shared/typeDef/apiFeedback";
import {HouseListingBlock} from "./HouseListingBlock";
import {IHouseCollection, IHouseItem} from "../../typeDef/hosueData";
import {HouseDetails} from "./HouseDetails";
import houseStyles from "../../css/house.module.css";
import {Button, Dropdown} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {ISecurityToken} from "../../../../redux";
import {checkUserIsLoggedIn, getSecurityTokenFromState} from "../../../../sharedLib/userLib";
import allActions from "../../../../redux/action/allActions";

interface IHouseLocation {
    locationName: string;
}
interface IHouseRegion {
    regionName: string;
    locationList: IHouseLocation[];
}
interface IHouseCountry {
    countryCode: string;
    regionList: IHouseRegion[];
}

export const HouseContainer: React.FC<{

}> = ({

}) => {
    const dispatch = useDispatch();
    
    // state
    // @ts-ignore
    const currentUser = useSelector(state => state.user);
    const [apiHouseListing, setApiHouseListing] = useState<IApiFeedback>();
    const [selectedHouseId, setSelectedHouseId] = useState<number>(null);
    const [apiHouseDetails, setApiHouseDetails] = useState<IApiFeedback>();
    const [dataHasChanged, setDataHasChanged] = useState<boolean>(false);
    const [actionMode, setActionMode] = useState<string>("list");
    const [houseDetailsData, setHouseData] = useState<IHouseItem>(null);
    // @ts-ignore
    const allHouseListing = useSelector(state => state.appFuncData[slhEntities.allHouseListing]);
    console.log("allHouseListing / State:");
    console.log(allHouseListing);
    
    // use-effect
    useEffect(() => {
        loadAllHouseListing();
    }, []);
    useEffect(() => {
        loadHouseDetails();
    }, [selectedHouseId]);
    
    // functions
    function callbackLoadAllHouseListing(serviceCall: ServiceCall) {
        const data = serviceCall.getResponseData();
        const apiHouseListing = {
            state: 'loaded',
            error: serviceCall.getRequestConfigStatusCode() === 0 ? '' : 'Warning/Error',
            data: data,
            msgList: serviceCall.getResponseInfo(),
        }
        // neu mit Redux Daten laden
        dispatch(allActions.AppFuncDataActions.appFuncDataSetFullData(slhEntities.allHouseListing, data));
        setApiHouseListing(apiHouseListing)
    }
    function loadAllHouseListing(): void {
        const houseListingService = new ServiceCall(slhServicePath.getAllHouseListing);
        houseListingService.executePost({}, callbackLoadAllHouseListing);
    }
    
    function callbackLoadHouseDetails(serviceCall: ServiceCall) {
        const data = serviceCall.getResponseData();
        const apiHouseDetails = {
            state: 'loaded',
            error: serviceCall.getRequestConfigStatusCode() === 0 ? '' : 'Warning/Error',
            data: data,
            msgList: serviceCall.getResponseInfo(),
        }
        // neu mit Redux Daten laden
        setApiHouseDetails(apiHouseDetails);
        setHouseData((apiHouseDetails.data));
    }
    function loadHouseDetails(): void {
        const houseDetailsService = new ServiceCall(slhServicePath.getHouseDetails);
        houseDetailsService.executePost({id: selectedHouseId}, callbackLoadHouseDetails);
    }
    
    function chooseAllHouses(withReload: boolean): void {
        if (withReload || dataHasChanged) {
            setDataHasChanged(false);
            loadAllHouseListing();
        }
        if (houseDetailsData) {
            setHouseData(null);
        }
        setSelectedHouseId(null)
        setActionMode("list");
    }
    function selectHouse(id: number) {
        setActionMode("view");
        setSelectedHouseId(id);
    }
    function addNewHouse(): void {
        setHouseData({houseSubject: ""});
        setActionMode("add");
    }
    
    // other
    if (!allHouseListing) {
        return null;
    }
    
    const userSecurityContext: ISecurityToken | undefined = getSecurityTokenFromState(currentUser);
    const userIsLoggedIn: boolean = checkUserIsLoggedIn(userSecurityContext);
    const houseListing: IHouseCollection = allHouseListing;
    const countries:IHouseCountry[] = [];
    houseListing.forEach((h: IHouseItem) => {
        let ctry: IHouseCountry = countries.find((c: IHouseCountry) => c.countryCode === h.countryName);
        if (!ctry) {
            countries.push({countryCode: h.countryName, regionList: []});
            ctry = countries[countries.length - 1];
        }
        let reg: IHouseRegion = ctry.regionList.find((r: IHouseRegion) => r.regionName === h.regionName);
        if (!reg) {
            ctry.regionList.push({regionName: h.regionName, locationList: []});
            reg = ctry.regionList[ctry.regionList.length - 1];
        }
        let loc: IHouseLocation = reg.locationList.find((l: IHouseLocation) => l.locationName === h.locationName);
        if (!loc) {
            reg.locationList.push({locationName: h.locationName});
        }
   })
    
    console.log("countries");
    console.log(countries);
    
    
    
    return (
        <>
            {/*
                -- Brot-Krümel: Titel-Navigation ----------------------------------------------------------------------
*/}
            <div className={"titleNavLineBlock"}>
                <div className={"titleNavLineItem"} onClick={() => chooseAllHouses(dataHasChanged)} >
                    all Houses
                </div>
                {selectedHouseId && houseDetailsData &&
                    <>
                        <div className={"titleNavLineSeperator"}/>
                        <div className={"titleNavLineItem"} >
                            House:  {houseDetailsData.houseSubject}
                        </div>
                    </>
                }
                <div className={houseStyles.divBlockAtRight}>
                    {actionMode === 'list' && userIsLoggedIn &&
                        <Button onClick={() => addNewHouse()}>Add new House</Button>
                    }
                    {actionMode === 'view' &&
                        <img src={require("../../../../img/close_32px.png")} alt={"Close"} title={"Back to Overview"} style={{cursor: "pointer"}} onClick={() => chooseAllHouses(true)} />
                    }
                </div>
            </div>
            <br />
    
            {actionMode === 'list' && // Listing
                <div>
                    <HouseListingBlock houseListing={houseListing} fnSelectHouse={selectHouse} />
                </div>
            }
            {"view|add|update|delete".includes(actionMode) && // Details: view / add / update / delete
                <HouseDetails houseDetailsData={houseDetailsData} fnLoadHouseDetails={loadHouseDetails} fnSetDataHasChanged={setDataHasChanged} fnChooseAllHouses={chooseAllHouses} actionMode={actionMode} fnSetActionMode={setActionMode} />
            }
        </>
    )
}

