import * as React from 'react';
import {Modal} from "react-bootstrap";
import ReactPlayer from 'react-player';
import {IFotoItem, IFotoItems} from "./fotoInterfaces"
import fotoStyles from "./fotoNavigor.module.css";
// import appStyles from "../css/app.module.css";

const imgPrev = require("../../img/prev_24px.png");
const imgNext = require("../../img/next_24px.png");


interface IProps {
    fotoList: IFotoItems;
    selectedFotoIdx: number;
    fnModalClose: () => void;
}

interface IState {
    currentFotoIdx: number;
}

export  class SharedBigFotoNavigator extends React.Component<IProps, IState> {
    
    public state = {
        currentFotoIdx: this.props.selectedFotoIdx,
    }
    
    handleClose = () => {
        this.props.fnModalClose();
    }
    
    goNextFoto = () => {
        let { currentFotoIdx } = this.state;
        const { fotoList } = this.props;
        currentFotoIdx++;
        if ((currentFotoIdx + 1) > fotoList.length) {
            currentFotoIdx = 0;
        }
        this.setState({currentFotoIdx});
    }
    
    goPrevFoto = () => {
        let { currentFotoIdx } = this.state;
        const { fotoList } = this.props;
        currentFotoIdx--;
        if (currentFotoIdx < 0 ) {
            currentFotoIdx = fotoList.length - 1;
        }
        this.setState({currentFotoIdx});
    }
    private checkIsVideo = (fotoItem: IFotoItem): boolean => {
        const videoEndingList =  ["mp4"];
        const fileEnding = fotoItem.ending ? fotoItem.ending : "";
        const videoListIdx = videoEndingList.findIndex((format: string) => format === fileEnding)
        return (videoListIdx >= 0);
    }
    
    
    render() {
        const { fotoList, selectedFotoIdx } = this.props;
        const { currentFotoIdx } = this.state;
        const show = true;
        const currentFoto = fotoList[currentFotoIdx];
        const fotoNr: number = currentFotoIdx + 1;
        const fotoNrString = fotoNr.toString();
        const fullFileName = currentFoto.dir + "/" + currentFoto.filename;
        const isVideo = this.checkIsVideo(currentFoto);
        
        return (
            <>
                <Modal show={show} onHide={this.handleClose} centered={true} dialogClassName={fotoStyles.modalBigFotoNavigator} >
                    <Modal.Header closeButton className={fotoStyles.modalHeaderBigFotoNavigator}>
                        Bild Navigator: Foto: {currentFotoIdx + 1} / {fotoList.length}
                    </Modal.Header>
                    <Modal.Body className={fotoStyles.modalBodyBigFotoNavigator}>
                        <table className={fotoStyles.carouselTabBigFotoNavigator}>
                            <tbody>
                            <tr>
                                <td className={fotoStyles.carouselLeftRightTd} onClick={this.goPrevFoto}>
                                    <img src={imgPrev} alt={"Prev"} title={"Vorheriges Bild"} />
                                </td>
                                <td className={fotoStyles.carouselImageTd}>
                                    {isVideo === true &&
                                        <ReactPlayer url={fullFileName} controls={true} className={fotoStyles.realPlayerBigFotoNavigator} />
                                    }
                                    {isVideo === false &&
                                        <img onClick={this.goNextFoto}
                                            className={fotoStyles.imgBildNavigator}
                                            src={fullFileName}
                                            alt={fotoNrString}
                                        />
                                    }
                                    {currentFoto.description &&
                                        <p><br />{currentFoto.description}</p>
                                    }
                                </td>
                                <td className={fotoStyles.carouselLeftRightTd} onClick={this.goNextFoto}>
                                    <img src={imgNext} alt={"Next"} title={"Nächstes Bild"} />
                                </td>
                            </tr>
                            </tbody>
                        
                        </table>
                    
                    </Modal.Body>
                </Modal>
            </>
        )
    }
    
}