import {actionCommands} from "../actionCommands";
const initialData: any = {
    sl6Club: [],
    sl6Girl: [],
    sl6GirlMap: [],
    currentUser: [],
};

const isDebugMode: boolean = false;

const appDataReducer = (state: any = initialData, action: any) => {
    const actionType: string = action.type;
    const entity: string = (action && action.payload && action.payload.entity) ? action.payload.entity : "";
    const data: any = (action && action.payload && action.payload.data) ? action.payload.data: null;
    const idName: string = (action && action.payload && action.payload.idName) ? action.payload.idName : "";
    if (isDebugMode) {
        console.log("appDataReducer:");
        console.log("- action.type: "+ actionType);
        console.log("- action.payload.entity: " + entity);
        console.log("- action.payload.data:");
        console.log(data);
        console.log("- action.payload.idName: "+ idName);
        console.log(action);
    }
    if (!entity || entity === "") {
        if (actionType.startsWith("@@redux/" ) || actionType.startsWith("@@INIT")) {
            return state;
        }
        console.log("Error: Empty Entity: not possible to proceed Dispath Action");
        console.log("ActionType: " + actionType);
        console.log("ActionData:");
        console.log(data)
        return state;
    }
    
    switch (action.type) {
        // ======================================================================================================
        // -- Set-Data-Full -------------------------------------------------------------------------------------
        //    erhält eine Liste von Daten und ersetzt eine allfällige frühere Version der Liste
        case actionCommands.appData.setDataFull:
            if (!Array.isArray(data)) {
                console.log("Error: an invalid data type submitted - needs to be an array");
                return state;
            }
            const newStateSetDataFull: any = Object.assign({}, state);
            newStateSetDataFull[entity] = data;
            return Object.assign({}, newStateSetDataFull);
        // =======================================================================================================
        // -- Add-Data-Item -----------------------------------------------------------------------------------
        //    erhält einen einzelnen Datensatz und ersetzt eine allfällige vorhandenen Datensatz mit der ID
        case actionCommands.appData.addDataItem:
            const newStateAddDataItem = Object.assign({}, state);
            const addIdx = newStateAddDataItem[entity].findIndex((item: any) => item[idName] === data[idName]);
            if (addIdx < 0) {
                newStateAddDataItem[entity].push(data);
            }
            return Object.assign({}, newStateAddDataItem);
        // =======================================================================================================
        // -- Update-Data-Item -----------------------------------------------------------------------------------
        //    erhält einen einzelnen Datensatz und ersetzt eine allfällige vorhandenen Datensatz mit der ID
        case actionCommands.appData.updateDataItem:
            if (!idName || idName === "") {
                console.log("Error: No ID-Name submitted");
                return state;
            }
//            console.log("updateDataItem:")
            const newStateUpdateDataItem: any = Object.assign({}, state);
//            console.log(newStateUpdateDataItem);
            const appDataUpdateDataList: any[] = newStateUpdateDataItem[entity];
//            console.log("appDataUpdateDataList:");
//            console.log(appDataUpdateDataList);
            
            const newAppDataUpdateDataList = appDataUpdateDataList.map((mItem: any) => {
                if (mItem[idName] === data[idName]) {
                    return Object.assign({}, data);
                } else {
                    return Object.assign({}, mItem);
                }
            })
//            console.log("newAppDataUpdateDataList");
//            console.log(newAppDataUpdateDataList);
            
            newStateUpdateDataItem[entity] = newAppDataUpdateDataList;
            return Object.assign({}, newStateUpdateDataItem);
        // =======================================================================================================
        // -- Delete-Data-Item -----------------------------------------------------------------------------------
        //    erhält einen einzelnen Datensatz und löscht eine allfällige vorhandenen Datensatz mit der ID
        case actionCommands.appData.deleteDataItem:
            if (!idName || idName === "") {
                console.log("Error: No ID-Name submitted");
                return state;
            }
//            console.log("deleteDataItem:")
            const newStateDeleteDataItem: any = Object.assign({}, state);
//            console.log(newStateDeleteDataItem);
            const appDataDeleteDataList: any[] = newStateDeleteDataItem[entity];
//            console.log("appDataDeleteDataList:");
//            console.log(appDataDeleteDataList);
            const newAppDataDeleteDataList = appDataUpdateDataList.map((mItem: any) => {
                if (mItem[idName] === data[idName]) {
                    return null;
                } else {
                    return Object.assign({}, mItem);
                }
            })
//            console.log("newAppDataDeleteDataList");
//            console.log(newAppDataDeleteDataList);
    
            newStateDeleteDataItem[entity] = newAppDataUpdateDataList;
//            console.log("newStateDeleteDataItem:");
//            console.log(newStateDeleteDataItem);
            return Object.assign({}, newStateDeleteDataItem);
        // =======================================================================================================
        // -- Other / Default
        default:
            return state;
    }
}

export default appDataReducer;