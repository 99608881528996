import React, {useEffect, useState} from 'react';
//import PropTypes from 'prop-types';

import {IHouseItem} from "../../typeDef/hosueData";
import clubStyles from "../../../sl6/pages/club/club.module.css";
import {Button, ButtonGroup} from "react-bootstrap";
import {InputText} from "../../../../component/input/InputText";
import {InputNumber} from "../../../../component/input/InputNumber";
import {NatListComponent} from "../../../../component/natListComponent/NatListComponent";
import {InputTextarea} from "../../../../component/input/InputTextarea";
import {NineRating} from "../../../../component/nineRating/NineRating";
import {ServiceCall} from "../../../../sharedLib/ServiceCall";
import slhServicePath from "../../config/slhServices.json";
import {ISecurityToken} from "../../../../redux";
import {checkUserIsLoggedIn, getSecurityTokenFromState} from "../../../../sharedLib/userLib";
import {useSelector} from "react-redux";
import {InputTextWithLabel} from "../../../../component/input/InputTextWithLabel";
import {InputNumberWithLabel} from "../../../../component/input/InputNumberWithLabel";
import {InputTextAreaWithLabel} from "../../../../component/input/InputTextAreaWithLabel";
import {InputNineRatingWithLabel} from "../../../../component/input/InputNineRatingWithLabel";

export const HouseDetailsInfoBlock: React.FC<{
    houseDetailsData: IHouseItem;
    actionMode: string;
    fnSetDataHasChanged: (newValue: boolean) => void;
    fnChooseAllHouses: (withReload: boolean) => void;
    fnSetActionMode: (mode: string) => void;
}> = ({
          houseDetailsData,
          actionMode,
          fnSetDataHasChanged,
          fnChooseAllHouses,
          fnSetActionMode
      }) => {
    
    
    // state
    // @ts-ignore
    const currentUser = useSelector(state => state.user);
    const [editHouseData, setEditHouseData] = useState<IHouseItem>(null);
    const [lastChanged, setLastChanged] = useState<Date>(null);
    const [lastSaved, setLastSaved] = useState<Date>(null);
    
    // use-effect
    useEffect(() => {
        setEditHouseData(houseDetailsData);
    }, [houseDetailsData]);
    
    useEffect(() => {
        const interval = setInterval(() => {
            checkIfShouldSave();
        }, 1000);
        return () => clearInterval(interval);
    })
    
    // functions
    function changeHandlerEditHouse (field: string, newValue: any): void {
        const newEditHouseData = Object.assign({}, editHouseData);
        // @ts-ignore
        newEditHouseData[field] = newValue;
        setEditHouseData(newEditHouseData);
        setLastChanged(new Date());
    }
    
    function checkIfShouldSave(): void {
        const currentDate = new Date();
        const currentDateGetTime = currentDate.getTime();
        const lastChangedGetTime = lastChanged?.getTime();
        const lastSavedGetTime = lastSaved?.getTime();
        if (editHouseData && actionMode === 'update') {
            if (lastChanged) { // es wurde auch etwas geändert
                if (lastChangedGetTime <= (currentDateGetTime - 2000)) {
                    if (!lastSaved || lastChangedGetTime > lastSavedGetTime) {
                        setLastSaved(currentDate);
                        saveHouseData();
                    }
                }
            }
            
        }
    }
    
    function callbackSaveHouseData(serviceCall: ServiceCall) {
        const data = serviceCall.getResponseData();
        const apiHouseDetails = {
            state: 'loaded',
            error: serviceCall.getRequestConfigStatusCode() === 0 ? '' : 'Warning/Error',
            data: data,
            msgList: serviceCall.getResponseInfo(),
        }
        fnSetDataHasChanged(true);
        if (actionMode === 'add' || actionMode === 'delete') {
            fnChooseAllHouses(true);
        }
    }
    
    function saveHouseData(): void {
        const userSecurityToken: ISecurityToken | undefined = getSecurityTokenFromState(currentUser);
        if (checkUserIsLoggedIn(userSecurityToken)) {
            const data: IHouseItem = Object.assign({}, editHouseData);
            if (actionMode === 'add') {
                data.wsMode = 'A';
            } else if (actionMode === 'update') {
                data.wsMode = 'U';
            }
            const saveHouseService = new ServiceCall(slhServicePath.saveHouseDetails);
            saveHouseService.executePost(data, callbackSaveHouseData);
        }
    }
    
    function openLink(link: string): void {
        if (link && link !== "") {
            const newWindow = window.open(link, '_blank', 'noopener,noreferrer');
            if (newWindow) newWindow.opener = null;
        }
    }
    
    // other
    if (!houseDetailsData || !editHouseData) {
        return null;
    }

    const userSecurityContext: ISecurityToken | undefined = getSecurityTokenFromState(currentUser);
    const userIsLoggedIn: boolean = checkUserIsLoggedIn(userSecurityContext);
    const isEditable = "add|update".includes(actionMode) && userIsLoggedIn;
    let pendingChanges: boolean = false;
    if (lastChanged && (lastSaved || lastSaved < lastChanged)) {
        if (!lastSaved || lastSaved.getTime() < lastChanged.getTime()) {
            pendingChanges = true;
        }
    }

    return (
        <>
            <div className={clubStyles.divBlock}>
                <div className={clubStyles.divBlockTitleRight}>
                    {!isEditable && userIsLoggedIn === true &&
                        <img src={require("../../../../img/edit_32px.png")} alt={"edit"} onClick={() => fnSetActionMode('update')} />
                    }
                    {isEditable && actionMode === "update" &&
                        <img src={require("../../../../img/noedit_32px.png")} alt={"edit"} onClick={() => fnSetActionMode('view')} />
                    }
                    {isEditable === true && pendingChanges === true && actionMode === "update" &&
                        <>
                            <Button variant={"dark"}>pending changes</Button>
                        </>
                    }
                    {isEditable === true && actionMode === "add" &&
                        <>
                            <Button variant={"primary"} onClick={() => saveHouseData()}>Save</Button> &nbsp; &nbsp;
                            <Button variant={"secondary"} onClick={() => fnChooseAllHouses(false)}>Cancel</Button>
                        </>
                    }
                </div>
                <div className={clubStyles.divBlockTitle}>
                    House Details
                </div>
                <br/>
                <table className={"App-TableBlock"} width={"100%"}>
                    <colgroup>
                        <col width={"10%"} />
                        <col width={"10%"} />
                        <col width={"10%"} />
                        <col width={"10%"} />
                        <col width={"10%"} />
                        <col width={"10%"} />
                        <col width={"10%"} />
                        <col width={"10%"} />
                        <col width={"10%"} />
                        <col width={"10%"} />
                    </colgroup>
                    <tbody>
                    <tr>
                        <td className={"App-TableBlockCellField"} colSpan={9}>
                            <InputTextWithLabel name={"houseSubject"} isEditable={isEditable}
                                                label={"Subject"}
                                       value={editHouseData.houseSubject}
                                       fnOnChange={changeHandlerEditHouse}/>
                        </td>
                        {actionMode === "view" &&
                            <td className={"App-TableBlockCellField"}>
                                <InputTextWithLabel name={"houseId"} isEditable={false}
                                                    label={"Id"}
                                                    value={editHouseData.houseId?.toString()} />
                            </td>
                        }
                    </tr>
                    <tr>
                        <td className={"App-TableBlockCellField"}>
                            <InputTextWithLabel name={"countryName"} isEditable={isEditable} label={"Country"}
                                       value={editHouseData.countryName}
                                       fnOnChange={changeHandlerEditHouse}/>
                        </td>
                        <td className={"App-TableBlockCellField"}>
                            <NatListComponent natString={editHouseData.countryName}/>
                        </td>
                        <td className={"App-TableBlockCellField"} colSpan={3}>
                            <InputTextWithLabel name={"regionName"} isEditable={isEditable} label={"Region"}
                                       value={editHouseData.regionName}
                                       fnOnChange={changeHandlerEditHouse}/>
                        </td>
                        <td className={"App-TableBlockCellField"} colSpan={5}>
                            <InputTextWithLabel name={"locationName"} isEditable={isEditable} label={"Location"}
                                       value={editHouseData.locationName}
                                       fnOnChange={changeHandlerEditHouse}/>
                        </td>
                    </tr>
                    <tr>
                        <td className={"App-TableBlockCellField"} colSpan={3}>
                            <InputNumberWithLabel name={"housePrice"} isEditable={isEditable} label={"Price"}
                                       value={editHouseData.housePrice} numDec={0}
                                       fnOnChange={changeHandlerEditHouse}/>
                        </td>
                        <td className={"App-TableBlockCellField"} colSpan={2} />
                        <td className={"App-TableBlockCellField"} colSpan={2}>
                            <InputTextWithLabel name={"buildAt"} isEditable={isEditable} label={"Buiild at"}
                                       value={editHouseData.buildAt}
                                       fnOnChange={changeHandlerEditHouse}/>
                        </td>
                        
                    </tr>
                    <tr>
                        <td className={"App-TableBlockCellField"} colSpan={2}>
                            <InputNumberWithLabel name={"numBedroom"} isEditable={isEditable} label={"# Bedroom"}
                                       value={editHouseData.numBedroom}
                                       fnOnChange={changeHandlerEditHouse}/>
                        </td>
                        <td className={"App-TableBlockCellField"} colSpan={2}>
                            <InputNumberWithLabel name={"numBathroom"} isEditable={isEditable} label={"# Bathroom"}
                                         value={editHouseData.numBathroom}
                                         fnOnChange={changeHandlerEditHouse}/>
                        </td>
                        <td className={"App-TableBlockCellField"} />
                        <td className={"App-TableBlockCellField"} colSpan={2}>
                            <InputNumberWithLabel name={"livingSpace"} isEditable={isEditable} label={"Living m2"}
                                         value={editHouseData.livingSpace}
                                         fnOnChange={changeHandlerEditHouse}/>
                        </td>
                        <td className={"App-TableBlockCellField"} colSpan={2}>
                            <InputNumberWithLabel name={"landSize"} isEditable={isEditable} label={"Land m2"}
                                         value={editHouseData.landSize}
                                         fnOnChange={changeHandlerEditHouse}/>
                        </td>
                    </tr>
                    <tr>
                        <td className={"App-TableBlockCellField"} colSpan={9}>
                            <InputTextWithLabel name={"houseUrl"} isEditable={isEditable} label={"House (URL)"}
                                       value={editHouseData.houseUrl}
                                       fnOnChange={changeHandlerEditHouse}/>
                        </td>
                        <td className={"App-TableBlockCellField"}>
                            <button onClick={() => openLink(editHouseData.houseUrl)}>Link</button>
                        </td>
                    </tr>
                    <tr>
                        <td className={"App-TableBlockCellField"} colSpan={10}>
                            <InputTextWithLabel name={"agencyName"} isEditable={isEditable} label={"Agency (Name)"}
                                       value={editHouseData.agencyName}
                                       fnOnChange={changeHandlerEditHouse}/>
                        </td>
                    </tr>
                    <tr>
                        <td className={"App-TableBlockCellField"} colSpan={9}>
                            <InputTextWithLabel name={"agencyUrl"} isEditable={isEditable} label={"Agency (URL)"}
                                       value={editHouseData.agencyUrl}
                                       fnOnChange={changeHandlerEditHouse}/>
                        </td>
                        <td className={"App-TableBlockCellField"}>
                            <button onClick={() => openLink(editHouseData.agencyUrl)}>Link</button>
                        </td>
                    </tr>
                    <tr>
                        <td className={"App-TableBlockCellField"} colSpan={10}>
                            <InputTextAreaWithLabel name={"houseComment"} isEditable={isEditable} label={"House Comment"}
                                       value={editHouseData.houseComment} rows={5}
                                       fnOnChange={changeHandlerEditHouse}/>
                        </td>
                    </tr>
                    <tr>
                        <td className={"App-TableBlockCellField"} colSpan={5}>
                            <InputNineRatingWithLabel name={"houseRating"} value={editHouseData.houseRating} label={"Rating"}
                                        isEditable={isEditable} fnOnChange={changeHandlerEditHouse}/>
                        </td>
                    </tr>
                    <tr>
                        <td className={"App-TableBlockCellField"} colSpan={10}>
                            <InputTextAreaWithLabel name={"ratingComment"} isEditable={isEditable} label={"Rating Comment"}
                                       value={editHouseData.ratingComment}
                                       fnOnChange={changeHandlerEditHouse}/>
                        </td>
                    </tr>
                    </tbody>
                </table>
                <br/>
            </div>
        
        
        </>
    )
}

