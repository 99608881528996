import React from 'react';

import {HouseDetailsInfoBlock} from "./HouseDetailsInfoBlock";
import {IHouseItem} from "../../typeDef/hosueData";
import {HouseDetailsImageBlock} from "./HouseDetailsImageBlock";

export const HouseDetails: React.FC<{
    houseDetailsData: IHouseItem;
    actionMode: string;
    fnLoadHouseDetails: () => void;
    fnSetDataHasChanged: (newValue: boolean) => void;
    fnChooseAllHouses: (withReload: boolean) => void;
    fnSetActionMode: (mode: string) => void;
}> = ({
    houseDetailsData,
    actionMode,
    fnLoadHouseDetails,  fnSetDataHasChanged, fnChooseAllHouses, fnSetActionMode
}) => {
    
    
    // state
    
    // use-effect
    
    // functions
    
    
    if (!houseDetailsData) {
        return null;
    }
    
    // other
    
    return (
        <>
            <table width={"100%"}>
                <colgroup>
                    <col width={"40%"} />
                    <col width={"60%"} />
                </colgroup>
                <tbody>
                    <tr>
                        <td style={{verticalAlign: "top"}}>
                            <HouseDetailsImageBlock houseDetailsData={houseDetailsData} fnLoadHouseDetails={fnLoadHouseDetails} actionMode={actionMode} fnSetDataHasChanged={fnSetDataHasChanged} />
                        </td>
                        <td style={{verticalAlign: "top"}}>
                            <HouseDetailsInfoBlock houseDetailsData={houseDetailsData} fnSetDataHasChanged={fnSetDataHasChanged} fnChooseAllHouses={fnChooseAllHouses} actionMode={actionMode} fnSetActionMode={fnSetActionMode}/>
                        </td>
                    </tr>
                </tbody>
            </table>
        </>
    )
}

