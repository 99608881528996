import {actionCommands} from "../actionCommands";
import {ISecurityToken} from "./userAction";

export const APPDATA_SETDATAFULL = (entity: string, data: any[]) => {
    return {
        type: actionCommands.appData.setDataFull,
        payload: {entity, data}
    };
};

export const APPDATA_ADDDATAITEM = (entity: string, data: any, idName: string) => {
    return {
        type: actionCommands.appData.addDataItem,
        payload: {entity, data, idName}
    };
}

export const APPDATA_UPDATEDATAITEM = (entity: string, data: any, idName: string) => {
    return {
        type: actionCommands.appData.updateDataItem,
        payload: {entity, data, idName}
    };
}

export const APPDATA_DELETEDATAITEM = (entity: string, data: any, idName: string) => {
    return {
        type: actionCommands.appData.deleteDataItem,
        payload: {entity, data, idName}
    };
}

const setDataFull = (entity: string, data: any[]) => {
    return {
        type: "APPDATA_SETDATAFULL",
        payload: {entity, data}
    };
};

const addDataItem = (entity: string, data: any, idName: string) => {
    return {
        type: "APPDATA_ADDDATAITEM",
        payload: {entity, data, idName}
    };
}

const updateDataItem = (entity: string, data: any, idName: string) => {
    return {
        type: "APPDATA_UPDATEDATAITEM",
        payload: {entity, data, idName}
    };
}

const deleteDataItem = (entity: string, data: any, idName: string) => {
    return {
        type: "APPDATA_DELETEDATAITEM",
        payload: {entity, data, idName}
    };
}


export default {
    setDataFull,
    addDataItem,
    updateDataItem,
    deleteDataItem
}