import {ISecurityToken} from "../redux";

interface ICurrentUserState {
    user?: ISecurityToken
}
export function getSecurityTokenFromState(currentUser: ICurrentUserState | undefined ): ISecurityToken {
    let userSecurityToken: ISecurityToken | undefined = undefined;
    if (currentUser && currentUser.user) {
        userSecurityToken = currentUser?.user;
    }
    return userSecurityToken
}

export function checkUserIsLoggedIn (securityContext: ISecurityToken | undefined): boolean {
    let isLoggedIn = false;
    if (securityContext && securityContext.tokenPk && securityContext.tokenPk !== "") {
        isLoggedIn = true;
    }
    return isLoggedIn;
}