import React from 'react';
import {InputText} from "../../../../component/input/InputText";
import {ServiceCall} from "../../../../sharedLib/ServiceCall";
import {MessageList} from "../../../../component/messageList/MessageList";
import {ClubGirlDynDetailInfo} from "../club/ClubGirDynDetailInfo";
import {IMessageList} from "../../../../typeDef/messageList";
import {IGirlItem, IGirlItems} from "../../typeDef/girlData";
import sl6Services from "../../config/sl6Services.json";
import {IGirlClubMapItem, IGirlClubMapItems} from "../../typeDef/clubData";
import {APPDATA_SETDATAFULL, IRootState} from "../../../../redux";
import {Dispatch} from "redux";
import {connect} from "react-redux";
import clubStyles from "../club/club.module.css";
import {getFirstImageFromFileList} from "../../../../sharedLib/fileLib";
import {getlocalHtdocsPath} from "../../config/getConfigData";
import {NatListComponent} from "../../../../component/natListComponent/NatListComponent";
import {LoadingInfo} from "../../../../component/loadingInfo/LoadingInfo";

const isHomeOrder: number[] = [1, 0];

interface IState {
  apiFeedback: {
      state: string;
      error: string;
      msgList?: IMessageList;
      
  };
  girlSearchName: string;
  selectedGirlId?: number;
  editGirl: IGirlItem;
  loadState: string;
}

const mapStateToProps = (state: IRootState) => {
    return {
        adSl6Girl: state.appData.sl6Girl
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    setDataFull: (entity: string, data: any) => {
        dispatch(APPDATA_SETDATAFULL(entity, data))
    }
})

type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type StateProps = ReturnType<typeof  mapStateToProps>;
type Props = DispatchProps & StateProps;

const emptyGirlPerClubListingData: IGirlItems = [];
const emptyMsgList: IMessageList = [];
const emptyGirlItem: IGirlItem = null;


export class GirlsOverviewContainer extends React.Component<Props, IState> {
    
    public state = {
        apiFeedback: {
            state: 'pending',
            error: '',
            msgList: emptyMsgList
        },
        girlSearchName: "",
        selectedGirlId: 0,
        editGirl: emptyGirlItem,
        loadState: '',
    }

    componentDidMount = () => {
        const girlListingService = new ServiceCall(sl6Services.getAllGirlListing);
        this.setState({loadState: "pending"});
        girlListingService.executePost({}, this.callbackLoadAllGirlListing);
    }

    callbackLoadAllGirlListing = (serviceCall: ServiceCall): void => {
        const apiFeedback = {
            state: 'loaded',
            error: serviceCall.getRequestConfigStatusCode() === 0 ? '' : 'Warning/Error',
            msgList: serviceCall.getResponseInfo(),
        }
        this.props.setDataFull("sl6Girl", serviceCall.getResponseData());
        this.setState({apiFeedback, loadState: 'complete'})
    }



    selectGirl = (girl: IGirlItem) => {
        if (girl) {
            this.setState({selectedGirlId: girl.girlId});
        } else {
            this.setState({selectedGirlId: 0});
        }
    }
    
    dispatchSetData = (entity: string, data: any, mode?: string, idName?: string): void => {
 //       this.props.setData(entity, data, mode, idName);
    }
    
    
    onChange = (what: any, name: string, value: string): void => {
        // @ts-ignore
        this.setState({[name]: value})
    }
    
    getClubNameList =(girlClubList: IGirlClubMapItems): string => {
        let retClubNames: string = "";
        isHomeOrder.forEach((isHomeValue) => {
            girlClubList.forEach((club: IGirlClubMapItem) => {
                if (isHomeValue === club.clubIsHome) {
                    if (retClubNames !== "") {
                        retClubNames = retClubNames + ", ";
                    }
                    retClubNames = retClubNames + club.clubName;
                    if (isHomeValue > 0) {
                        retClubNames = retClubNames + " (H)";
                    }
                }
            })
            
        })
        return retClubNames;
    }
    
    changeHandlerEditGirl = (action: string, field: string, newValue: any): void => {
        console.log("ClubDynContainer.changeHandlerSelectedGirl:");
        console.log("action: " + action + " / " + field + " / " + newValue);
        const { selectedGirlId, editGirl } = this.state;
        if (action === 'reset') {
            if (editGirl.wsMode === 'A') {
                // spezial-Fall 'Add' --> wieder zurück auf Liste
                this.setState({selectedGirlId: 0})
            } else {
                // Normal-Fall 'Edit' --> einfach neu aus Store auslesen
                editGirl.wsMode = "";
                const sl6GirlFromStoreList = this.props.adSl6Girl;
                if (sl6GirlFromStoreList) {
                    const selectedGirl = sl6GirlFromStoreList.find((gItem: IGirlItem) => gItem.girlId === selectedGirlId);
                    this.selectGirl(selectedGirl);
                }
//                this.setState({selectedGirlId: selectedGirlBackup.girlId});
            }
        } else if (action === 'change') {
            const { editGirl } = this.state;
            // @ts-ignore
            editGirl[field] = newValue;
            this.setState({editGirl});
        }
    }
    
    render() {
        const { girlSearchName, selectedGirlId, editGirl, apiFeedback, loadState } = this.state;
        const { adSl6Girl } = this.props;
        
        console.log(adSl6Girl);
    
        const localHtDocsPath = getlocalHtdocsPath();
        const filteredgirlDataList = adSl6Girl.filter((girl: IGirlItem) => girl?.girlName?.toLowerCase().includes(girlSearchName.toLowerCase()));
        
    
        let selectedGirlFromStore: IGirlItem = null;
        if (selectedGirlId > 0) {
//            selectedGirlFromStore = selectedClubFromStore.girlList.find((girlItem: IGirlItem) => girlItem.girlId === selectedGirlId);
            selectedGirlFromStore = adSl6Girl.find((girlItem: IGirlItem) => girlItem.girlId === selectedGirlId);
        }
        let showGirl: IGirlItem = selectedGirlFromStore;
        if (editGirl && (editGirl.wsMode === "A" || editGirl.wsMode === "U")) {
            showGirl = editGirl;
        }
    
        if (loadState === "pending") {
            return (
                <>
                    <LoadingInfo />
                </>
            )
        }
        
        
        return (
            <>
                {/*
                -- Brot-Krümel: Titel-Navigation ----------------------------------------------------------------------
*/}
                <div className={"titleNavLineBlock"}>
                    <div className={"titleNavLineItem"} onClick={() => this.selectGirl(null)}>
                        all Girls
                    </div>
                    {selectedGirlFromStore !== null &&
                        <>
                            <div className={"titleNavLineSeperator"}/>
                            <div className={"titleNavLineItem"}>
                                Girl: {selectedGirlFromStore.girlName}
                            </div>
                        </>
                    }
                </div>

                <MessageList msgList={apiFeedback.msgList} />
                <br />
    
                {selectedGirlId === 0 &&
                    <div>
                        Girl Name: <InputText name={"girlSearchName"} value={girlSearchName} fnChangeHandler={this.onChange} width={"20%"} isEditable={true} />
                        &nbsp; &nbsp; Total: {filteredgirlDataList.length} Entries
                        <br />
                        <br />

                        <div className={"newLine"}>
                            <ul key={"clsb_ul"} className={clubStyles.clubGirlListUl}>
                                {filteredgirlDataList.map((girl: IGirlItem, girlIdx: number) => {
                                    let isActive: boolean = false;
                                    const imgFile = getFirstImageFromFileList(girl.fileItems);
                                    const cssGirlLabel = (isActive ? clubStyles.clubGirlListLabelActive : clubStyles.clubGirlListLabelInactive);
                                    const imgPath = imgFile ?  localHtDocsPath + "girl/girl_" + girl.girlId + "/" + imgFile.filename : "";
                                    return (
                                        <React.Fragment key={"rf_" + girl.girlId}>
                                            <li key={"li_" + girlIdx} onClick={() => this.selectGirl(girl)}>
                                                <div key={"li_div_1_" + girl.girlId}
                                                     className={clubStyles.clubGirlListDiv}>
                                                    {imgPath !== "" &&
                                                        <img className={clubStyles.clubGirlListImg}
                                                             src={imgPath} alt={""}/>
                                                    }
                                                </div>
                                                <div key={"li_div_2_" + girl.girlId} className={cssGirlLabel}>
                                                    {girl.girlName} - {girl.age}
                                                    <br/>
                                                    <NatListComponent natString={girl.nat}/>
                                                    <br />
                                                    {this.getClubNameList(girl.girlClubList)}
                                                </div>
                                            </li>
            
                                        </React.Fragment>
                                    )
                                })}
                            
                            </ul>
                        </div>
    
{/*
                        <br />
                        <br />
                        <table width={"100%"} className={"App-TableBlock"}>
                            <thead>
                            <tr>
                                <th>Name</th>
                                <th>Nat</th>
                                <th>age</th>
                                <th>id</th>
                                <th>Club Name</th>
                            </tr>
                            </thead>
                            <tbody>
                            {filteredgirlDataList.map((girl: IGirlItem) =>
                                <tr key={"tr_girl_" + girl.girlId} onClick={() => this.selectGirl(girl)} >
                                    <td key={"td_girlName_" + girl.girlId}>
                                        {girl.girlName}
                                    </td>
                                    <td key={"td_girlNat_" + girl.clubId}>
                                        {girl.nat}
                                    </td>
                                    <td key={"td_girlAge_" + girl.clubId}>
                                        {girl.age}
                                    </td>
                                    <td key={"td_girlId_" + girl.clubId}>
                                        {girl.girlId}
                                    </td>
                                    <td key={"td_clubName_" + girl.clubId}>
                                        {this.getClubNameList(girl.girlClubList)}
                                    </td>
                                </tr>
                            )}
                            </tbody>
                        </table>
*/}

                    </div>

                }
                {selectedGirlId > 0 &&
                    <ClubGirlDynDetailInfo fnSelectGirl={this.selectGirl} selectedGirlId={selectedGirlId} fnLoadSingleClub={null} fnChangeHandler={this.changeHandlerEditGirl} showGirl={showGirl} />
                }
            </>
        )
    }
}

export const GirlsOverview = connect(mapStateToProps, mapDispatchToProps)(GirlsOverviewContainer);
