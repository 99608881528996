import * as React from 'react';
import {useLocation} from 'react-router-dom';
import {Nav, Navbar, NavDropdown} from 'react-bootstrap';
import {IAppData, IAppDataTopNavSubItem} from '../typeDef/appData';

import startStyles from './start.module.css';
import {getApplication} from "./getApplication";
import {useSelector, useDispatch} from "react-redux";
import {IRootState} from "../redux";

import { counterDecrement, counterIncrement } from "../redux";
import {UserInPageHeader} from "../app/shared/pages/user/UserInPageHeader";

export const PageHeader: React.FC = (): JSX.Element => {
    
    const location = useLocation();
    const app: IAppData = getApplication(location.pathname);
    const counter = useSelector<IRootState, number>(state => state.counter);
    const dispatch = useDispatch();
    
//    const counter = useSelector((state: IRootState) => {state.counter}, number);
    
    return (
        <>
            <div className={startStyles.appHeader}>
                <Navbar expand="lg" variant="light">
                    <Navbar.Brand>
                        <div className={startStyles.appHeaderName}>
                            <>
                                {app.appTitle}
{/*
                                {counter}
                                <button onClick={() => dispatch(counterIncrement(5))}>+</button>
                                <button onClick={() => dispatch(counterDecrement())}>-</button>
*/}
                            </>
                        </div>
                    </Navbar.Brand>
                        <Navbar.Collapse id="basic-navbar-nav">
                            {app.topNav && app.topNav.length > 0 &&
                            <Nav className="me-auto">
                                {app.topNav.map((topNavItem, tniIdx) => {
                                    if (topNavItem.type === "link") {
                                        if (topNavItem.img && topNavItem.img !== "") {
                                            return (
                                                <Nav.Link href={topNavItem.actionUrl}
                                                          key={"navLink_" + tniIdx}><img src={topNavItem.img} alt={topNavItem.label} title={topNavItem.label} /></Nav.Link>
                                            )
                                        } else {
                                            return (
                                                <Nav.Link href={topNavItem.actionUrl}
                                                          key={"navLink_" + tniIdx}>{topNavItem.label}</Nav.Link>
                                            )
                                        }
                                    }
                                    if (topNavItem.type === "navDropdown") {
                                        return (
                                            <NavDropdown title={topNavItem.label} id={"topNavItem_" + tniIdx}
                                                         key={"topNavItem_" + tniIdx}>
                                                {topNavItem.subItems.map((subItem: IAppDataTopNavSubItem, siIdx) => {
                                                    if (subItem.type === "navItem") {
                                                        return (
                                                            <NavDropdown.Item href={subItem.actionUrl}
                                                                              id={"topNavDropdown_" + tniIdx + "_" + siIdx}
                                                                              key={"topNavDropdown_" + tniIdx + "_" + siIdx}>
                                                                {subItem.label}
                                                            </NavDropdown.Item>
                                                        )
                                                    }
                                                    if (subItem.type === "seperator") {
                                                        return (
                                                            <NavDropdown.Divider
                                                                key={"topNavSeperator_" + tniIdx + "_" + siIdx}/>
                                                        )
                                                    }
                                                    return null;
                                                })}
                                            </NavDropdown>
                                        )
                                    }
                                    return null;
                                })}
                            </Nav>
                            }
                            <UserInPageHeader />
                        </Navbar.Collapse>
                
                </Navbar>
            </div>
        </>
    )
}