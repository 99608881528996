import {combineReducers} from "redux";

import counterReducer from "./reducer/counter";
import userReducer from "./reducer/user";
import appDataReducer from "./reducer/appData";
import appFuncDataReducer from "./reducer/appFuncData";


const allReducers = combineReducers({
    counter: counterReducer,
    user: userReducer,
    appData: appDataReducer,
    appFuncData: appFuncDataReducer,
})

export default allReducers;
export type IRootState = ReturnType<typeof allReducers>

export * from "./action";
export * from "./reducer";
export * from "./typeRootState";