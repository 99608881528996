import React, {useEffect, useState} from 'react';
import {IMessageList} from "../../typeDef/messageList";
import styles from "./message.module.css";
import {Badge} from "react-bootstrap";


export const MessageList: React.FC<{
    msgList: IMessageList;
}> = ({
          msgList
      }) => {
    // state
    const [moreSelectedList, setMoreSelectedList] = useState<number[]>([]);
    
    // use-effect

    // functions
    function addDelMoreIndex(idx: number): void {
        const newMoreSelectedList: number[] = [];
        let hasFoundIt: boolean = false;
        moreSelectedList.forEach((value) => {
          if (value === idx) {
              hasFoundIt = true;
          }   else {
              newMoreSelectedList.push(value);
          }
        })
        if (!hasFoundIt) {
            newMoreSelectedList.push(idx);
        }
        setMoreSelectedList(newMoreSelectedList)
    }
    function checkIsInMoreSelected(idx: number): boolean {
        return (moreSelectedList.indexOf(idx) > -1);
    }

    // other
    
    if (!msgList || msgList.length === 0) {
        return;
    }

    return (
        <>
            {msgList.length > 0 &&
                <>
                    <table width={"100%"}>
                        <tbody>
                        {msgList.map((msg, msgIdx) => {
                            let stylesTableMessage = styles.tableMessageWarning;
                            if (msg.msgType === "error") {
                                stylesTableMessage = styles.tableMessageError;
                            }
                    
                            return (
                                <tr key={"msg_" + msg.msgNo} className={stylesTableMessage}>
                                    <td>
                                        {msg.msgType}
                                    </td>
                                    <td>
                                        {msg.msgText}
                                        {msg.msgDetails && msg.msgDetails !== "" && !checkIsInMoreSelected(msgIdx) &&
                                            <img src={require("../../img/arrowDown_16px.png")} alt={"..."} onClick={() => addDelMoreIndex(msgIdx)} style={{marginLeft: "20px"}} />
                                        }
                                        {msg.msgDetails &&  checkIsInMoreSelected(msgIdx) &&
                                            <img src={require("../../img/arrowUp_16px.png")} alt={"..."} onClick={() => addDelMoreIndex(msgIdx)} style={{marginLeft: "20px"}} />
                                        }
                                        {checkIsInMoreSelected(msgIdx) &&
                                            <>
                                                <br /><br />
                                                <u>Details:</u><br/>
                                                {msg.msgDetails}
                                            </>
                                        }
                                    </td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>
                    <br />
                </>
            }
        </>
    )
}
