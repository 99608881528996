import React, {useEffect, useState} from 'react';
import styles from "./input.module.css";


export const InputTextWithLabel: React.FC<{
    label: string;
    name: string;
    value: string;
    placeholder?: string;
    isEditable?: boolean;
    width?: string;
    type?: string;
    fnOnChange?: (name: string, value: any) => void;
    fnMouseClick?: (e: any) => void;
}> = ({
          label,
          name,
          value,
          placeholder,
          isEditable,
          width,
          type,
          fnOnChange,
          fnMouseClick
      }) => {
    
    
    // state
    
    // use-effect
    
    
    // functions
    const onChangeHandler = (e: any):void => {
        if (fnOnChange && typeof fnOnChange == "function") {
            fnOnChange(e.target.name, e.target.value);
        }
    }
    const onMouseEnter = (e: any): void => {
        if (fnMouseClick && typeof fnMouseClick == "function") {
            fnMouseClick(e);
        }
    }
    
    // Default-Werte
    if (!width) {
        width = "100%"
    }
    if (!placeholder) {
        placeholder = "";
    }
    if (isEditable === undefined) {
        isEditable = true;
    }
    if (!type) {
        type = "text";
    }
    
    const newInputTextDiv = isEditable ? styles.NewInputTextDivEditable : styles.NewInputTextDivNotEditable;
    const newInputTextInputInput = isEditable ? styles.NewInputTextInputInputEditable : styles.NewInputTextInputInputNotEditable;
    
    return (
        <div className={newInputTextDiv}>
            <div className={styles.NewInputTextLabel}>
                {label}
            </div>
            <div className={newInputTextInputInput}>
                <input className={newInputTextInputInput}
                       name={name}
                       style={{width: width}}
                       type={type}
                       value={value}
                       readOnly={!isEditable}
                       disabled={!isEditable}
                       onClick={(evt) => onMouseEnter(evt)}
                       onChange={(evt) => onChangeHandler(evt)} />
                
            
            </div>
        
        </div>
    )
}

