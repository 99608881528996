import React, {useEffect, useState} from 'react';
import {Button, Col, Form, Modal, Row} from "react-bootstrap";
import sharedStyles from "../../css/shared.module.css";
import {ServiceCall} from "../../../../sharedLib/ServiceCall";
import sharedServices from "../../config/sharedServices.json";
import {IApiFeedback} from "../../typeDef/apiFeedback";
import {MessageList} from "../../../../component/messageList/MessageList";
import {getNewId} from "../../../../sharedLib/getIdLib";
//import PropTypes from 'prop-types';


interface IUserForm {
    pwType: string;
    userName: string;
    userPw: string;
}

export const CreateUser: React.FC<{
}> = ({
}) => {
    // state
    const [userForm, setUserForm] = useState<IUserForm>({pwType: "password", userName: "", userPw: ""})
    const [apiCreateUser, setApiCreateUser] = useState<IApiFeedback>();
    
    // use-effect
    
    // functions
    function onChangeHandler (evt: any): void {
        const newUserForm: IUserForm = Object.assign({}, userForm);
        const name = evt.target.name;
        const value = evt.target.value;
        // @ts-ignore
        newUserForm[name] = value;
        setUserForm(newUserForm);
    }
    
    function changePwType (newValue: string): void {
        const newUserForm: IUserForm = Object.assign({}, userForm);
        newUserForm.pwType = newValue;
        setUserForm(newUserForm);
    }
    
    function callbackCreateNewUser (sc: ServiceCall): void {
        setApiCreateUser(sc.getApiFeedback());
        if (sc.getRequestConfigStatusCode() === 0) {
        } else {
            const messageList = sc.getResponseInfo();
        }
    }
    
    function submitCreateUser(): void {
        let isAllOk: boolean = true;
        if (!userForm.userName || userForm.userName === "") {
            isAllOk = false;
            alert("Please enter the user login name");
        }
        if (!userForm.userPw || userForm.userPw === "") {
            isAllOk = false;
            alert("Please enter the user password");
        }
        if (isAllOk === true) {
            const sc = new ServiceCall(sharedServices.createNewUser);
            sc.executePost({userName: userForm.userName, userPw: userForm.userPw, userPk: getNewId()}, callbackCreateNewUser);
        }
    }
    
    // other
    
    return (
        <>
            <h4>Create User</h4>
            <div>
                {apiCreateUser && apiCreateUser.msgList &&
                    <MessageList msgList={apiCreateUser.msgList} />
                }
                <Form>
                    <Form.Group as={Row} className="mb-5 mt-5" controlId="formPlaintextEmail">
                        <Form.Label column sm="3">
                            User Name
                        </Form.Label>
                        <Col sm="8">
                            <Form.Control type="text" placeholder="User Login Name" name={"userName"} value={userForm.userName} onChange={onChangeHandler} />
                        </Col>
                        <Col sm="1" />
                    </Form.Group>
            
                    <Form.Group as={Row} className="mb-5" controlId="formPlaintextPassword">
                        <Form.Label column sm="3">
                            Password
                        </Form.Label>
                        <Col sm="7">
                            <Form.Control type={userForm.pwType} placeholder="Password" name={"userPw"} value={userForm.userPw} onChange={onChangeHandler} />
                        </Col>
                        <Col sm="1">
                            <img src={require("../../../../img/eyePreview_32px.png")} alt={"Preview"} onMouseEnter={() => changePwType("text")} onMouseLeave={() => changePwType("password")} />
                        </Col>
                    </Form.Group>
                    <Button variant="primary" type="button" className={"me-3"} onClick={() => submitCreateUser()}>
                        Create User
                    </Button>
        
                </Form>
    
            </div>
        </>
    )
}

