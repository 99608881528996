import React, {useState} from 'react';

import {getFileType, getFileTypeFromFileName, getFirstImageFromFileList} from "../../../../sharedLib/fileLib";
import clubStyles from "../../../sl6/pages/club/club.module.css";
import {IHouseItem} from "../../typeDef/hosueData";
import {getlocalHtdocsPath} from "../../../sl6/config/getConfigData";
import ReactPlayer from "react-player";
import {SharedBigFotoNavigator} from "../../../../component/fotoNavigator/BigFotoNavigator";
import {IFileItem, IFileItems} from "../../../../typeDef/fileItem";
import {FileUploader} from "../../../../component/fileUploader/FileUploader";
import slhServicePath from "../../../house/config/slhServices.json";
import sl6ServicePath from "../../../sl6/config/sl6Services.json";
import {ConfirmDialog} from "../../../../component/customModalDialog/ConfirmDialog";
import {ServiceCall} from "../../../../sharedLib/ServiceCall";
import {useSelector} from "react-redux";
import {ISecurityToken} from "../../../../redux";
import {checkUserIsLoggedIn, getSecurityTokenFromState} from "../../../../sharedLib/userLib";

const mp4Icon = require("./../../../../img/mp4_64px.png");
const unknownIcon = require("./../../../../img/unknownFileType_64px.png");
const deleteIcon = require("./../../../../img/delete_32px.png");


export const HouseDetailsImageBlock: React.FC<{
    houseDetailsData: IHouseItem;
    actionMode: string;
    fnLoadHouseDetails: () => void;
    fnSetDataHasChanged: (newValue: boolean) => void;
}> = ({
          houseDetailsData,
          actionMode,
          fnLoadHouseDetails,
          fnSetDataHasChanged
      }) => {
    
    
    // state
    // @ts-ignore
    const currentUser = useSelector(state => state.user);
    const [selectedImage, setSelectedImage] = useState<string>("");
    const [isShowNavigator, setIsShowNavigator] = useState<boolean>(false);
    const [askForDeleteImage, setAskForDeleteImage] = useState<boolean>(false);
    
    // use-effect
    
    // functions
    
    
    if (!houseDetailsData) {
        return null;
    }
    
    function onFileUploadCompleted(): void {
        fnSetDataHasChanged(true);
        fnLoadHouseDetails();
    }
    
    function callbackAfterDeleteHouseImage(sc: ServiceCall) {
        if (sc.getRequestConfigStatusCode() === 0) {
            setSelectedImage("")
            fnLoadHouseDetails();
        } else {
            alert("Error:")
        }
    }
    
    function callbackAfterConfirmDeleteImage(action: string) {
        setAskForDeleteImage(false);
        if (action === "yes") {
            const payload = {
                houseId: houseDetailsData.houseId,
                imageFile: selectedImage
            }
            const sc = new ServiceCall(slhServicePath.singleHouseDeleteImage);
            sc.setRequestParam("wsMode", "U");
            sc.executePost(payload, callbackAfterDeleteHouseImage);
        }
    }
    
    // other
    const userSecurityContext: ISecurityToken | undefined = getSecurityTokenFromState(currentUser);
    const userIsLoggedIn: boolean = checkUserIsLoggedIn(userSecurityContext);
    const localHouseFilePath = getlocalHtdocsPath() + "house/house_" + houseDetailsData.houseId + "/";
    const transformedFileList: IFileItems = [];
    houseDetailsData.fileItems?.forEach((file: IFileItem) => {
        if (file.ending !== "json" && file.isDir === false) {
            const newFile: IFileItem = {
                filename: file.filename,
                dir: localHouseFilePath,
                ending: file.ending,
                isDir: file.isDir,
                description: file.filename,
            }
            transformedFileList.push(newFile);
        }
    })
    
    const firstImgFile = getFirstImageFromFileList(transformedFileList);
    let showImg = selectedImage;
    if (showImg === "" && firstImgFile) {
        showImg = firstImgFile.filename;
    }
    const showImageFileType = getFileTypeFromFileName(showImg);
    const currentFotoIdx: number = transformedFileList.findIndex((file: IFileItem) => file.filename === showImg);
    
    return (
        <>
            <div>
                {transformedFileList.map((img, imgIdx) => {
                    const imageFileType = getFileType(img);
                    return (
                        <React.Fragment key={"fileList_" + houseDetailsData.houseId + "_" + imgIdx}>
                            {imageFileType === "video" &&
                                <img src={mp4Icon} onClick={() => setSelectedImage(img.filename)}
                                     alt={"Video"}/>
                            }
                            {imageFileType === "image" &&
                                <img
                                    className={img.filename === showImg ? clubStyles.girlThumbImgSelected : clubStyles.girlThumbImg}
                                    src={localHouseFilePath + img.filename}
                                    onClick={() => setSelectedImage(img.filename)} alt={"Foto"}/>
                            }
                            {imageFileType !== "video" && imageFileType !== "image" &&
                                <img
                                    className={img.filename === showImg ? clubStyles.girlThumbImgSelected : clubStyles.girlThumbImg}
                                    src={unknownIcon} onClick={() => setSelectedImage(img.filename)}
                                    alt={"Unknown Type"}/>
                            }
                        </React.Fragment>
                    )
                })}
                {"view|update".includes(actionMode) && userIsLoggedIn &&
                    <>
                        <FileUploader iconSize={"l"} previewSize={"m"} uploadType={"slhHouse"}
                                      uploadId={houseDetailsData.houseId}
                                      onFileUploadCompleted={onFileUploadCompleted}
                                      phpExecuteProcedure={sl6ServicePath.fileUploader}/>
                    </>
                }
            </div>
            <div>
                {showImg && showImg !== "" && showImageFileType === "video" &&
                    <ReactPlayer url={localHouseFilePath + showImg} controls={true}/>
                }
                {showImg && showImg !== "" && showImageFileType !== "video" &&
                    <img className={clubStyles.girlFullImg} src={localHouseFilePath + showImg} onClick={() => setIsShowNavigator(true)}
                         alt={"Foto"}/>
                }
            </div>
            <div>
                {showImg}
                {selectedImage && userIsLoggedIn &&
                    <img style={{paddingLeft: "50px"}} src={deleteIcon} alt={"delete"} onClick={() => setAskForDeleteImage(true)} />
                }
            </div>
            {isShowNavigator === true &&
                <SharedBigFotoNavigator fotoList={transformedFileList} selectedFotoIdx={currentFotoIdx} fnModalClose={() => setIsShowNavigator(false)} />
            }
    
            {askForDeleteImage &&
                <ConfirmDialog
                    fnClose={callbackAfterConfirmDeleteImage}
                    confirmTitle={"Sicherheitsabfrage"}
                    confirmText={"Wollen Sie dieses File '" + showImg + "' wirklich löschen?"}
                    confirmHint={"Das File wird komplett gelöscht. Dieser Vorgang kann nicht rückgängig gemacht werden."}
                />
            }
            
        </>
    )
}

