const isDebugMode: boolean = true;

const appFuncData = (state: any = {}, action: any) => {
    const actionType: string = action.type;
    const entity: string = (action && action.payload && action.payload.entity) ? action.payload.entity : "";
    const data: any = (action && action.payload && action.payload.data) ? action.payload.data: null;
    const idName: string = (action && action.payload && action.payload.idName) ? action.payload.idName : "";
    
    if (isDebugMode) {
        console.log("appDataReducer:");
        console.log("- action.type: "+ actionType);
        console.log("- action.payload.entity: " + entity);
        console.log("- action.payload.data:");
        console.log(data);
        console.log("- action.payload.idName: "+ idName);
        console.log(action);
    }
    
    switch (action.type) {
        // ======================================================================================================
        // -- Set-Data-Full -------------------------------------------------------------------------------------
        //    erhält eine Liste von Daten und ersetzt eine allfällige frühere Version der Liste
        case "APPFUNCDATA_SETDATAFULL":
            if (isDebugMode) {
                console.log("case: APPFUNCDATA_SETDATAFULL");
            }
            if (!Array.isArray(data)) {
                console.log("Error: an invalid data type submitted - needs to be an array");
                return state;
            }
            if (isDebugMode) {
                console.log("sett Data in Store:");
                console.log(data);
            }
            const newStateSetDataFull: any = Object.assign({}, state);
            newStateSetDataFull[entity] = data;
            console.log("newStateSetDataFull[" + entity + "]:");
            console.log(newStateSetDataFull)
            return {...newStateSetDataFull};
        case "APPFUNCDATA_OTHER":
            return {
                ...state, appFuncData: {}
            }
        default:
            return state;
    }
}

export default appFuncData;