import React, {useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {ISecurityToken} from "../../../../redux";
import allActions from "../../../../redux/action/allActions";
import {Badge, Nav} from "react-bootstrap";
import {LoginForm} from "./LoginForm";
import {getSecurityTokenFromState} from "../../../../sharedLib/userLib";


export const UserInPageHeader: React.FC<{
}> = ({
      }) => {
    const dispatch = useDispatch();
    
    // state
    // @ts-ignore
    const currentUser = useSelector(state => state.user);
    const [showUserLoginForm, setShowUserLoginForm] = useState<boolean>(false);
    
    // use-effect
    
    // functions
    function userLogout(): void {
        dispatch(allActions.userActions.userLogout());
    }
    
    // other
    const userSecurityContext: ISecurityToken | undefined = getSecurityTokenFromState(currentUser);
    const userIsLoggedIn: boolean = (userSecurityContext && userSecurityContext.userName) ? true : false;
    const userNameSplit: string[] = userIsLoggedIn ? userSecurityContext.userName.split(" ") : [];
    const userCaps = userNameSplit.map((name: string) => {
        return name.substring(0,1).toUpperCase();
    })
    
    return (
        <>
            <Nav className={"me-auto"}>
                {userIsLoggedIn &&
                    <Nav.Link href={"#"}>
                        <h4>
                            <Badge bg="secondary" pill={true}>
                                {userCaps}
                            </Badge> &nbsp;
                            <img src={require("../../../../img/logout_24px.png")} alt={"Log-Out"} onClick={() => userLogout()} />
                        </h4>
                    </Nav.Link>
                }
                {userIsLoggedIn === false &&
                    <Nav.Link href={"#"} onClick={() => setShowUserLoginForm(true)}>
                        <img src={require("../../../../img/login_32px.png")} />
                    </Nav.Link>
                }
                {showUserLoginForm === true &&
                    <LoginForm fnOnClose={() => setShowUserLoginForm(false)} />
                }
            </Nav>
        </>
    )
}


/*
import React from 'react';
import {Badge, Nav} from "react-bootstrap";
import {LoginForm} from "./LoginForm";
import {IRootState} from "../../../../redux";
import {appDataMapDispatchToProps} from "../../../../redux/appDataMapDispatchToProps";
import {connect} from "react-redux";
import {ISecurityToken} from "../../../../redux";
import {getSecurityTokenFromState} from "../../../../sharedLib/userLib";
import allActions from "../../../../redux/action/allActions";
//import PropTypes from 'prop-types';

interface IState {
    showUserLoginForm:boolean;
}

const mapStateToProps = (state: IRootState) => {
    return {
        currentUser: state.user
    }
}

type DispatchProps = ReturnType<typeof appDataMapDispatchToProps>;
type StateProps = ReturnType<typeof  mapStateToProps>;
type Props = DispatchProps & StateProps;

export class UserInPageHeaderComponent extends React.Component<Props, IState> {
    
    public state = {
        showUserLoginForm: false,
    }
    
    private openUserLogin = (): void => {
        this.setState({showUserLoginForm: true});
    }

    private userLogout = (): void => {
        this.props.luserActions.userLogout();
    }
    
    private closeUserLogin = (): void => {
        this.setState({showUserLoginForm: false});
    }
    
    
    render() {
        
        const { showUserLoginForm } = this.state;
        const { currentUser } = this.props;
        return (
            <>
            </>
        )
    }
}

export const UserInPageHeader = connect(mapStateToProps, appDataMapDispatchToProps)(UserInPageHeaderComponent);
*/