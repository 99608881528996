import userActions from './userAction';
import AppDataActions  from './appDataAction';
import AppFuncDataActions from "./appFuncDataActions";

const allActions = {
    userActions,
    AppDataActions,
    AppFuncDataActions,
}

export default allActions;