import {IGirlItem} from "../../typeDef/girlData";

const newGirl: IGirlItem = {
    age: null,
    girlId: 1,
    girlName: "",
    girlExtention: "",
    numHadSex: 0,
    hair: "",
    height: 0,
    intimity: "",
    lang: "",
    nat: "",
    serviceInfo: "",
    tits: "",
    weight: 0,
    wsMode: "A",
    girlInfo: "",
    girlTattoo: "",
    firstActivationYear: new Date().getFullYear(),
    lastInactivationYear: 0,
    ratingBody: 6,
    ratingFace: 6,
    ratingServices: 0,
    ratingComment: "",
    girlClubList: [],
    fileItems: [],
    clubId: null
}

export function getNewGirlObject (clubId: number) {
    const newGirlObject = Object.assign({}, newGirl);
    newGirlObject.clubId = clubId;
    return newGirlObject;
}
