// import {ISecurityToken} from "./userAction";

const appFuncDataSetFullData = (entity: string, data: any[]) => {
    return {
        type: "APPFUNCDATA_SETDATAFULL",
        payload: {entity, data}
    };
};

const appFuncDataOther = () => {
    return {
        type: "APPFUNCDATA_OTHER"
    };
};

export default {
    appFuncDataSetFullData, appFuncDataOther
}