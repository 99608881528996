import * as React from 'react';

import startStyles from './start.module.css';
import {Routes, Route} from "react-router-dom";

import steuern2021Paths from "../app/steuern2021/config/steuern2021Paths.json";
import sl6Paths from "../app/sl6/config/sl6Paths.json";
import gameDokoPaths from "../app/sldoko/config/gameDokoPaths.json";
import musicPaths from "../app/music/config/musicPaths.json";
import fotobookPaths from "../app/fotobook/config/fotobookPaths.json";
import sightseeingPaths from "../app/sightseeing/config/sightseeingPaths.json";
import {AppContentPortal} from "./AppContentPortal";
import {AppContentSteuern2021} from "../app/steuern2021/start/AppContentSteuern2021";
import {ClubDynContainer} from "../app/sl6/pages/club/ClubDynContainer";
import {GirlsOverview} from "../app/sl6/pages/girl/GirlsOverview";
import {Test} from "../app/sl6/pages/admin/Test";
import {ArtistContainer} from "../app/music/pages/artist/ArtistContainer";
import {PlayList} from "../app/music/pages/playList/PlayList";
import {TopicContainer} from "../app/fotobook/pages/topic/TopicContainer";
import {AppContentSightseeing} from "../app/sightseeing/start/AppContentSightseeing";
import {PornStar} from "../app/sl6/pages/pornstar/PornStar";
import {GameAdmin} from "../app/sldoko/pages/gameAdmin/GameAdmin";
import {GameChart} from "../app/sldoko/pages/gameChart/GameChart";
import {GameHistory} from "../app/sldoko/pages/gameHistory/GameHistory";
import slhPaths from "../app/house/config/slhPaths.json";
import {HouseContainer} from "../app/house/pages/houseListing/HouseContainer";
import sharedPaths from "../app/shared/config/sharedPaths.json";
import {CreateUser} from "../app/shared/pages/user/CreateUser";

export  class AppRouting extends React.Component<{}, {}> {
    
    
    render() {
        return (
            <div className={startStyles.AppContentDiv}>
                <Routes>
                    {/* -- STELU Applications Portal ---------------------------------------------------------- */}
                    <Route  path="/" element={<AppContentPortal />} />
                    
                    {/* -- Steuern2021 ------------------------------------------------------------------------ */}
                    <Route path={steuern2021Paths.steuern2021Home} element={<AppContentSteuern2021 />} />
    
                    {/* -- SL6 -------------------------------------------------------------------------------- */}
                    <Route path={sl6Paths.club} element={<ClubDynContainer />} />
                    <Route path={sl6Paths.girl} element={<GirlsOverview />} />
                    <Route path={sl6Paths.adminTest} element={<Test />} />
                    <Route path={sl6Paths.pornstar} element={<PornStar />} />
    
                    {/* -- SLDoko -------------------------------------------------------------------------------- */}
                    <Route path={gameDokoPaths.gameHistory} element={<GameHistory />} />
                    <Route path={gameDokoPaths.gameChart} element={<GameChart />} />
                    <Route path={gameDokoPaths.gameAdmin} element={<GameAdmin />} />
                    
                    {/* -- Music ------------------------------------------------------------------------------ */}
                    <Route path={musicPaths.musicArtistList} element={<ArtistContainer />} />
                    <Route path={musicPaths.musicPlayList} element={<PlayList />} />
    
                    {/* -- Fotobook ------------------------------------------------------------------------------ */}
                    <Route path={fotobookPaths.fotobookTopic} element={<TopicContainer />} />
    
                    {/* -- Sightseeing ------------------------------------------------------------------------------ */}
                    <Route path={sightseeingPaths.sightseeingHome} element={<AppContentSightseeing />} />
    
                    {/* -- House ------------------------------------------------------------------------------ */}
                    <Route path={slhPaths.houseHome} element={<HouseContainer />} />
    
                    {/* -- Shared ------------------------------------------------------------------------------ */}
                    <Route path={sharedPaths.createUser} element={<CreateUser />} />
                    
                    {/* -- STELU Applications Portal (falls nicht gefunden ------------------------------------ */}
                    <Route  path="/*" element={<AppContentPortal />} />
                    
                </Routes>
            </div>        )
    }
    
}