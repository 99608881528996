import React, {useState} from 'react';
import {Button, Col, Form, Modal, Row} from "react-bootstrap";
import sharedStyles from "../../css/shared.module.css";
import {ServiceCall} from "../../../../sharedLib/ServiceCall";
import sharedServices from "../../config/sharedServices.json";
import {IApiFeedback} from "../../typeDef/apiFeedback";
import {MessageList} from "../../../../component/messageList/MessageList";
import {useDispatch} from "react-redux";
//import PropTypes from 'prop-types';
import {ISecurityToken} from "../../../../redux";
import allActions from "../../../../redux/action/allActions";

interface IUserForm {
    pwType: string;
    userName: string;
    userPw: string;
}

export const LoginForm: React.FC<{
    fnOnClose: () => void;
}> = ({
    fnOnClose
}) => {
    const dispatch = useDispatch();

    // state
    const [userForm, setUserForm] = useState<IUserForm>({pwType: "password", userName: "", userPw: ""})
    const [apiUserLogin, setApiUserLogin] = useState<IApiFeedback>();
    
    // use-effect
    
    // functions
    function onChangeHandler (evt: any): void {
        const newUserForm: IUserForm = Object.assign({}, userForm);
        const name = evt.target.name;
        // @ts-ignore
        newUserForm[name] = evt.target.value;
        setUserForm(newUserForm);
    }
    
    function changePwType (newValue: string): void {
        const newUserForm: IUserForm = Object.assign({}, userForm);
        newUserForm.pwType = newValue;
        setUserForm(newUserForm);
    }
    
    function callbackSubmitLogin (sc: ServiceCall): void {
        setApiUserLogin(sc.getApiFeedback());
        if (sc.getRequestConfigStatusCode() === 0) {
            const userResponse = sc.getResponseData();
            const userSecurityToken: ISecurityToken = {
                securityKey: userResponse?.securityToken?.securityKey,
                tokenPk: userResponse?.securityToken?.tokenPk,
                userName: userResponse?.securityToken?.userName,
                userPk: userResponse?.securityToken?.userPk,
                validUntil: userResponse?.securityToken?.validUntil
            };
            
            // dispatch(allActions.AppDataActions.setDataFull("currentUser", [userSecurityToken]));
            dispatch(allActions.userActions.userLogin(userSecurityToken))
            fnOnClose();
        } else {
            const messageList = sc.getResponseInfo();
            this.setState({messageList});
        }
    }
    
    function submitLogin(): void {
        let isAllOk: boolean = true;
        if (!userForm.userName || userForm.userName === "") {
            isAllOk = false;
            alert("Please enter the user login name");
        }
        if (!userForm.userPw || userForm.userPw === "") {
            isAllOk = false;
            alert("Please enter the user password");
        }
        if (isAllOk === true) {
            const sc = new ServiceCall(sharedServices.userLogin);
            sc.executePost({userName: userForm.userName, userPw: userForm.userPw}, callbackSubmitLogin);
        }
    }
    
    // other
    
    return (
        <>
            <Modal show={true} onHide={fnOnClose} centered={true} dialogClassName={sharedStyles.modalWindowHalf} >
                <Modal.Header closeButton className={sharedStyles.modalHeader}>
                    <Modal.Title>User Login</Modal.Title>
                </Modal.Header>
                <Modal.Body className={sharedStyles.modalBody}>
                    <div>
                        {apiUserLogin && apiUserLogin.msgList &&
                            <MessageList msgList={apiUserLogin.msgList} />
                        }
                        <Form>
                            <Form.Group as={Row} className="mb-5 mt-5" controlId="formPlaintextEmail">
                                <Form.Label column sm="3">
                                    User Name
                                </Form.Label>
                                <Col sm="8">
                                    <Form.Control type="text" placeholder="User Login Name" name={"userName"} value={userForm.userName} onChange={onChangeHandler} />
                                </Col>
                                <Col sm="1" />
                            </Form.Group>
                    
                            <Form.Group as={Row} className="mb-5" controlId="formPlaintextPassword">
                                <Form.Label column sm="3">
                                    Password
                                </Form.Label>
                                <Col sm="7">
                                    <Form.Control type={userForm.pwType} placeholder="Password" name={"userPw"} value={userForm.userPw} onChange={onChangeHandler} />
                                </Col>
                                <Col sm="1">
                                    <img src={require("../../../../img/eyePreview_32px.png")} alt={"Preview"} onMouseEnter={() => changePwType("text")} onMouseLeave={() => changePwType("password")} />
                                </Col>
                            </Form.Group>
                            <Button variant="primary" type="button" className={"me-3"} onClick={() => submitLogin()}>
                                Login
                            </Button>
                            <Button variant="secondary" type="reset" className={"me-3"} onClick={fnOnClose}>
                                Cancel
                            </Button>
{/*
                            <Button variant="secondary" type="button" className={"me-3"} onClick={() => createNewUser()}>
                                Create User
                            </Button>
*/}
                
                        </Form>
            
                    </div>
        
                </Modal.Body>
            </Modal>
        </>
    )
}

