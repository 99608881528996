import React, {useEffect, useState} from 'react';
//import PropTypes from 'prop-types';

import clubStyles from "../../../sl6/pages/club/club.module.css";
import houseStyles from "../../css/house.module.css";
import slhPaths from "../../config/slhPaths.json";

import {IHouseCollection, IHouseItem} from "../../typeDef/hosueData";
import {IFileItem} from "../../../../typeDef/fileItem";
import {getFirstImageFromFileList} from "../../../../sharedLib/fileLib";
import {getlocalHtdocsPath} from "../../../sl6/config/getConfigData";
import {NatListComponent} from "../../../../component/natListComponent/NatListComponent";
import {generatePath} from "react-router";
import {formatNumberToString} from "../../../../sharedLib/numberLib";

const localHtDocsPath = getlocalHtdocsPath();

export const HouseListingBlock: React.FC<{
    houseListing: IHouseCollection;
    fnSelectHouse: (id: number) => void;
}> = ({
    houseListing, fnSelectHouse
}) => {
    // state
    
    // use-effect
    
    // functions
    
    // other
    
    return (
        <>
            {/*
                -- Brot-Krümel: Titel-Navigation ----------------------------------------------------------------------
*/}
            <div className={"newLine"}>
                <ul key={"houseBlock_ul"} className={houseStyles.houseListUl}>
                    {houseListing.map((house: IHouseItem) => {
                        const imgFile: IFileItem = getFirstImageFromFileList(house.fileItems);
                        const imgPath = imgFile ?  localHtDocsPath + "house/house_" + house.houseId + "/" + imgFile.filename : "";

                        return (
                            <li key={"houseBlock.ul." + house.houseId} onClick={() => fnSelectHouse(house.houseId)} style={{cursor: "pointer"}}>
                                <div className={houseStyles.houseListingBlockTitleDiv}>
                                    {house.houseSubject}
                                </div>
                                <div className={houseStyles.houseListingBlockListDiv}>
                                    <div>
                                        {imgPath !== "" &&
                                            <img className={houseStyles.houseListingBlockListImg}
                                                 src={imgPath} alt={""}/>
                                        }
                                    </div>
                                </div>
                                <div className={houseStyles.houseListingBlockTextDiv}>
                                    <table width={"100%"}>
                                        <colgroup>
                                            <col width={"40%"} />
                                            <col width={"30%"} />
                                            <col width={"30%"} />
                                        </colgroup>
                                        <tbody>
                                        <tr>
                                            <td>
                                                Price: {formatNumberToString(house.housePrice, 0)}
                                            </td>
                                            <td>
                                                Bed: {house.numBedroom}
                                            </td>
                                            <td>
                                                Bath: {house.numBathroom}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan={3}>
                                                <NatListComponent natString={house.countryName}/>&nbsp;&nbsp;&nbsp;
                                                {house.regionName}&nbsp;&nbsp;/&nbsp;&nbsp;{house.locationName}<br/>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </li>
                            
                        )
                    } )}
                    
                </ul>
            </div>
        
        
        
        </>
    )
}

