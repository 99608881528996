import React from 'react';
import {Modal, Spinner} from "react-bootstrap";

interface IProps {
    type?: "modal" | "normal";
}
interface IState {
    internalShow: boolean;
}

export class LoadingInfo extends React.Component<IProps, IState> {
    public state = {
        internalShow: true,
    }
    
    private setInternalShow = (newState: boolean): void => {
        this.setState({internalShow: newState});
    }
    
    
    render() {
        const { internalShow } = this.state;
        const { type } = this.props;
        if (internalShow && internalShow === true) {
            if (type === "modal") {
                return (
                    <>
                        <Modal show={internalShow} onHide={() => this.setInternalShow(false)} keyboard={false}>
                            <Modal.Body>
                                <div style={{width: "100%", textAlign: "center"}} >
                                    Loading Data ... Please wait &nbsp; &nbsp;
                                    <Spinner animation="border" role="status" />
                                </div>
                            </Modal.Body>
                        </Modal>
        
                    </>
                )
            }
            return (
                <>
                    <br/>
                    <div style={{width: "100%", textAlign: "left", paddingLeft: "50px"}} >
                        Loading Data ... Please wait &nbsp; &nbsp;
                        <Spinner animation="border" role="status" />
                    </div>
                </>
            )
        }
        return null;
    }
}
