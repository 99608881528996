import React, {useEffect, useState} from 'react';
import styles from "./input.module.css";

const emptyStar = require("../../img/empty_star_16.png");
const filledStar = require("../../img/filled_star_16.png");
const closeZero = require("../../img/closeX_16px.png");
const nineArray = [1, 2, 3, 4, 5, 6, 7, 8, 9];

export const InputNineRatingWithLabel: React.FC<{
    label: string;
    name: string;
    value?: number;
    isEditable?: boolean;
    width?: string;
    fnOnChange?: (name: string, value: any) => void;
    fnMouseClick?: (e: any) => void;
}> = ({
          label,
          name,
          value,
          isEditable,
          width,
          fnOnChange,
          fnMouseClick
      }) => {
    
    
    // state
    
    // use-effect
    
    
    // functions
    const onChangeHandler = (newValue: number):void => {
        if (isEditable === true) {
            fnOnChange(name, newValue);
        }
    }
    const onMouseEnter = (e: any): void => {
        if (fnMouseClick && typeof fnMouseClick == "function") {
            fnMouseClick(e);
        }
    }
    
    // Default-Werte
    if (!width) {
        width = "100%"
    }
    if (isEditable === undefined) {
        isEditable = true;
    }
    
    const newInputTextDiv = isEditable ? styles.NewInputTextDivEditable : styles.NewInputTextDivNotEditable;
    const newInputTextInputInput = isEditable ? styles.NewInputTextInputInputEditable : styles.NewInputTextInputInputNotEditable;
    
    return (
        <div className={newInputTextDiv}>
            <div className={styles.NewInputTextLabel}>
                {label}
            </div>
            <div className={newInputTextInputInput}>
                {nineArray.map((loopNr) => {
                    const loopImage = loopNr <= value ? filledStar : emptyStar;
                    const leftMargin = loopNr === 4 || loopNr === 7 ? 15 : 0;
                    return (
                        <img key={"nineRating_" + loopNr} src={loopImage} alt={"loopNr"} style={{marginLeft: leftMargin + "px"}} onClick={() => onChangeHandler(loopNr)} />
                    )
                })}
                {isEditable &&
                    <img src={closeZero} alt={"0"} style={{marginLeft: "15px"}} onClick={() => onChangeHandler(0)} />
                }
            </div>
    
        </div>
    )
}

