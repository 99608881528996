import React from 'react';
import {InputText} from "../../../../component/input/InputText";
import {InputNumber} from "../../../../component/input/InputNumber";
import {InputTextarea} from "../../../../component/input/InputTextarea";
import {Button, ButtonGroup} from "react-bootstrap";
import ReactPlayer from 'react-player';
import {getlocalHtdocsPath} from "../../config/getConfigData";
import {getFileType, getFileTypeFromFileName, getFirstImageFromFileList} from "../../../../sharedLib/fileLib";
import {NineRating} from "../../../../component/nineRating/NineRating";
import {IGirlItem} from "../../typeDef/girlData";
import {IGirlClubMapItem} from "../../typeDef/clubData";
import {IFileItems, IFileItem} from "../../../../typeDef/fileItem";
import {NatListComponent} from "../../../../component/natListComponent/NatListComponent";
import clubStyles from "./club.module.css";
import genAppStyles from "../../../../css/appGeneric.module.css";
import sl6ServicePath from "../../config/sl6Services.json";
import {ServiceCall} from "../../../../sharedLib/ServiceCall";
import {connect} from "react-redux";
import {appDataMapDispatchToProps} from "../../../../redux/appDataMapDispatchToProps";
import {IRootState} from "../../../../redux";
import {FileUploader} from "../../../../component/fileUploader/FileUploader";
import {ConfirmDialog} from "../../../../component/customModalDialog/ConfirmDialog";
import {EditGirlClubMap} from "./EditGirlClubMap";
import {SharedBigFotoNavigator} from "../../../../component/fotoNavigator/BigFotoNavigator";

const mp4Icon = require("./../../../../img/mp4_64px.png");
const unknownIcon = require("./../../../../img/unknownFileType_64px.png");
const fileUploadIcon = require("./../../../../img/fileuploader_32px.png");
const deleteIcon = require("./../../../../img/delete_32px.png");

interface ICompProps {
    selectedGirlId: number;
    fnSelectGirl: (girl: IGirlItem) => void;
    showGirl: IGirlItem;
    fnChangeHandler: (action: string, name?: string, newValue?: any) => void;
    fnLoadSingleClub: (id: number) => void;
}


interface IState {
    selectedImg: string;
    selectedGirlClub?: IGirlClubMapItem;
    askForDeleteGirl: boolean;
    askForDeleteImage: boolean;
    isShowFotoNavigator: boolean;
}

const mapStateToProps = (state: IRootState) => {
    return {
        adSl6Girl: state.appData.sl6Girl
    }
}

type DispatchProps = ReturnType<typeof appDataMapDispatchToProps>;
type StateProps = ReturnType<typeof mapStateToProps>;
type AllProps = DispatchProps & StateProps & ICompProps;

const emptySelectedGirlClub: IGirlClubMapItem = null;
const emptyGirlItem: IGirlItem = null;

export class ClubGirlDynDetailInfoComponent extends React.Component<AllProps, IState> {
    
    public state = {
        selectedImg: "",
        selectedGirlClub: emptySelectedGirlClub,
        askForDeleteGirl: false,
        askForDeleteImage: false,
        isShowFotoNavigator: false,
    }
    
    activateEditMode = (newMode: string): void => {
        const {fnChangeHandler} = this.props;
        fnChangeHandler("change", "wsMode", newMode)
    }
    resetEditMode = () => {
        const {fnChangeHandler} = this.props;
        fnChangeHandler('reset');
    }
    activateAskForDeleteGirl = () => {
      this.setState({askForDeleteGirl: true});
    }
    activateAskForDeleteImage = () => {
        this.setState({askForDeleteImage: true});
    }
    
    callbackSaveGirlData = (sc: ServiceCall): void => {
        const {fnSelectGirl} = this.props;
        if (sc.getRequestConfigStatusCode() === 0) {
            const wsMode = sc.getRequestParam("wsMode");
            const data = sc.getResponseData();
            sc = undefined;
            if (wsMode === "A") {  // add
                console.log("data to store");
                console.log(data);
                this.props.addDataItem("sl6Girl", data, "girlId");
                fnSelectGirl(data);
            } else if (wsMode === "U") { // Update callbackSaveGirlData or deleteImage
                this.props.updateDataItem("sl6Girl", Object.assign({}, data), "girlId");
                fnSelectGirl(data);
            } else if (wsMode === "D") {  // Delete
                this.props.deleteDataItem("sl6Girl", data, "girlId");
                fnSelectGirl(null);
            }
        } else {
            console.log("else of statusCode = 0");
            console.log(sc.getResponseInfo());
            console.log(sc.getResponseData());
            alert("Error:")
        }
    }
    
    saveGirlData = (): void => {
        const {showGirl} = this.props;
        const sc = new ServiceCall(sl6ServicePath.saveGirlDetails);
        sc.setRequestParam("wsMode", showGirl.wsMode);
        sc.executePost(showGirl, this.callbackSaveGirlData);
    }
    
    selectImage = (filename: string) => {
        this.setState({selectedImg: filename})
    }
    
    actionChangeHandler = (what: string) => {
        /*
                this.setState({selectedImg: ""});
                this.props.fnChangeHandler(what);
        */
    }
    
    navigateTo = (action: string): void => {
    
    }
    
    editChangeHandler = () => {
        
    }
    
    selectGirlClubMap = (gcItem: IGirlClubMapItem) => {
        if (gcItem) {
            gcItem.wsMode = "";
        }
        this.setState({selectedGirlClub: gcItem});
    }
    
    selectAddClubMap = (): void => {
        const {showGirl} = this.props;
        const newGirlClubMap: IGirlClubMapItem = {
            girlId: showGirl.girlId,
            clubId: 0,
            clubName: "",
            clubIsHome: 0,
            isActive: 1,
            girlRef: "",
            wsMode: "A"
        }
        this.setState({selectedGirlClub: newGirlClubMap});
    }
    
    private callbackAfterSingleGirlDetails = (sc: ServiceCall) => {
        console.log("ClubGirlDynDetailInfo.callbackAfterSingleGirlDetails");
        const data = sc.getResponseData();
        this.props.updateDataItem("sl6Girl", Object.assign({}, data), "girlId");
        this.props.fnSelectGirl(data);
        
    }
    
    private callbackFileUploadCompleted = () => {
        const {selectedGirlId} = this.props;
        const sc = new ServiceCall(sl6ServicePath.singleGirlDetails);
        sc.executePost({id: selectedGirlId}, this.callbackAfterSingleGirlDetails);
    }
    
    private callbackAskForDeleteGirl = (action: string): void => {
        this.setState({askForDeleteGirl: false});
        if (action === "yes") {
            const {showGirl} = this.props;
            showGirl.wsMode = "D";
            const sc = new ServiceCall(sl6ServicePath.saveGirlDetails);
            sc.setRequestParam("wsMode", showGirl.wsMode);
            sc.executePost(showGirl, this.callbackSaveGirlData);
        }
    }
    
    private callbackAskForDeleteImage = (action: string): void => {
        this.setState({askForDeleteImage: false});
        if (action === "yes") {
            const {showGirl} = this.props;
            const {selectedImg} = this.state;
            const payload = {
                girlId: showGirl.girlId,
                imageFile: selectedImg
            }
            const sc = new ServiceCall(sl6ServicePath.singleGirlDeleteImage);
            sc.setRequestParam("wsMode", "U");
            sc.executePost(payload, this.callbackSaveGirlData);
        }
    }
    
    private closeEditGirlClubMap = (): void => {
        this.selectGirlClubMap(null);
    }
    
    render() {
        
        const {selectedImg, askForDeleteGirl, askForDeleteImage, selectedGirlClub, isShowFotoNavigator} = this.state;
        const {showGirl, fnChangeHandler} = this.props;
        
        let isEditable = showGirl.wsMode === "A" || showGirl.wsMode === "U";
        let imgFileList: IFileItems = [];
        if (showGirl.fileItems) {
            imgFileList = showGirl.fileItems.filter(item => item.isDir === false);
        }
        
        if (showGirl) {
            const localGirlFilePath = getlocalHtdocsPath() + "girl/girl_" + showGirl.girlId + "/";
            const firstImgFile = getFirstImageFromFileList(showGirl.fileItems);
            let showImg = selectedImg;
            if (showImg === "" && firstImgFile) {
                showImg = firstImgFile.filename;
            }
            const showImageFileType = getFileTypeFromFileName(showImg);
            const firstActivationYearText: string = "" + (showGirl.firstActivationYear ? showGirl.firstActivationYear : "-");
            const lastInactivationYearText: string = "" + (showGirl.lastInactivationYear ? showGirl.lastInactivationYear : "-");
            const currentFotoIdx: number = imgFileList.findIndex((file: IFileItem) => file.filename === showImg);
            const transformedFileList: IFileItems = [];
            imgFileList.forEach((file: IFileItem) => {
                if (file.ending !== "json" && file.isDir === false) {
                    const newFile: IFileItem = {
                        filename: file.filename,
                        dir: localGirlFilePath,
                        ending: file.ending,
                        isDir: file.isDir,
                        description: file.filename,
                    }
                    transformedFileList.push(newFile);
                }
            })
    
    
            return (
                <>
                    <table width={"100%"}>
                        <tbody>
                        <tr>
                            <td className={clubStyles.girlFullBlockTd}>
                                {transformedFileList.map((img, imgIdx) => {
                                    const imageFileType = getFileType(img);
                                    return (
                                        <React.Fragment key={"fileList_" + showGirl.girlId + "_" + imgIdx}>
                                            {imageFileType === "video" &&
                                                <img src={mp4Icon} onClick={() => this.selectImage(img.filename)}
                                                     alt={"Video"}/>
                                            }
                                            {imageFileType === "image" &&
                                                <img
                                                    className={img.filename === showImg ? clubStyles.girlThumbImgSelected : clubStyles.girlThumbImg}
                                                    src={localGirlFilePath + img.filename}
                                                    onClick={() => this.selectImage(img.filename)} alt={"Foto"}/>
                                            }
                                            {imageFileType !== "video" && imageFileType !== "image" &&
                                                <img
                                                    className={img.filename === showImg ? clubStyles.girlThumbImgSelected : clubStyles.girlThumbImg}
                                                    src={unknownIcon} onClick={() => this.selectImage(img.filename)}
                                                    alt={"Unknown Type"}/>
                                            }
                                        </React.Fragment>
                                    )
                                })}
                                {showGirl.wsMode === "" &&
                                    <>
                                        <FileUploader iconSize={"l"} previewSize={"m"} uploadType={"sl6Girl"}
                                                      uploadId={showGirl.girlId}
                                                      onFileUploadCompleted={this.callbackFileUploadCompleted}
                                                      phpExecuteProcedure={sl6ServicePath.fileUploader}/>
                                    </>
                                }
                                
                                <br/>
                                {showImg && showImg !== "" && showImageFileType === "video" &&
                                    <ReactPlayer url={localGirlFilePath + showImg} controls={true}/>
                                }
                                {showImg && showImg !== "" && showImageFileType !== "video" &&
                                    <img className={clubStyles.girlFullImg} src={localGirlFilePath + showImg} onClick={() => this.setState({isShowFotoNavigator: true})}
                                         alt={"Foto"}/>
                                }
                                <div>
                                    {showImg}
                                    {selectedImg &&
                                        <img style={{paddingLeft: "50px"}} src={deleteIcon} alt={"delete"} onClick={() => this.activateAskForDeleteImage()} />
                                    }
                                </div>
                            </td>
                            <td className={clubStyles.girlFullBlockTd}>
                                <div className={clubStyles.divBlock}>
                                    <div className={clubStyles.divBlockTitleRight}>
                                        {isEditable === false &&
                                            <>
                                                <ButtonGroup className="me-2">
                                                    <Button variant={'outline-secondary'}
                                                            style={{marginRight: "10px"}}
                                                            onClick={() => this.activateEditMode('U')}>Edit</Button>
                                                </ButtonGroup>
                                                <ButtonGroup className="me-2">
                                                    <Button variant={'outline-secondary'}
                                                            onClick={this.activateAskForDeleteGirl}>Delete</Button>
                                                </ButtonGroup>
                                            </>
                                        }
                                        {isEditable !== false &&
                                            <>
                                                <ButtonGroup className="me-2">
                                                    <Button variant={'primary'}
                                                            onClick={this.saveGirlData}>Save</Button>
                                                </ButtonGroup>
                                                <ButtonGroup className="me-2">
                                                    <Button variant={'outline-secondary'}
                                                            onClick={this.resetEditMode}>Cancel</Button>
                                                </ButtonGroup>
                                            </>}
                                    
                                    </div>
                                    <div className={clubStyles.divBlockTitle}>
                                        Girl Details
                                    </div>
                                    <table className={"App-TableBlock"}>
                                        <tbody>
                                        <tr>
                                            <td/>
                                            <td>
                                                {showGirl.wsMode === "A" &&
                                                    <h4>Add new girl</h4>
                                                }
                                            </td>
                                        </tr>
                                        <tr>
                                            <th className={"App-TableBlockCellLabel"}>
                                                Name:
                                            </th>
                                            <td className={"App-TableBlockCellField"}>
                                                <InputText name={"girlName"} isEditable={isEditable}
                                                           width={"calc(80%)"} value={showGirl.girlName}
                                                           fnChangeHandler={fnChangeHandler}/>
                                                <InputNumber name={"girliD"} isEditable={false}
                                                             width={"calc(20% - 20px)"} value={showGirl.girlId}
                                                             fnChangeHandler={fnChangeHandler}/>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th className={"App-TableBlockCellLabel"}>
                                                girl Extention:
                                            </th>
                                            <td className={"App-TableBlockCellField"}>
                                                <InputText name={"girlExtention"} isEditable={isEditable}
                                                           width={"calc(100% - 20px)"} value={showGirl.girlExtention}
                                                           fnChangeHandler={fnChangeHandler}/>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th className={"App-TableBlockCellLabel"}>
                                                Nationality:
                                            </th>
                                            <td className={"App-TableBlockCellField"}>
                                                <InputText name={"nat"} isEditable={isEditable}
                                                           width={"calc(50% - 20px)"} value={showGirl.nat}
                                                           fnChangeHandler={fnChangeHandler}/>
                                                <NatListComponent natString={showGirl.nat}/>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th className={"App-TableBlockCellLabel"}>
                                                Age:
                                            </th>
                                            <td className={"App-TableBlockCellField"}>
                                                <InputNumber name={"age"} isEditable={isEditable}
                                                             width={"calc(100% - 20px)"} value={showGirl.age}
                                                             fnChangeHandler={fnChangeHandler}/>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th className={"App-TableBlockCellLabel"}>
                                                Height / Weight:
                                            </th>
                                            <td className={"App-TableBlockCellField"}>
                                                <InputNumber name={"height"} isEditable={isEditable}
                                                             value={showGirl.height} width={"30%"}
                                                             fnChangeHandler={fnChangeHandler}/> cm / &nbsp;
                                                <InputNumber name={"weight"} isEditable={isEditable}
                                                             value={showGirl.weight} width={"30%"}
                                                             fnChangeHandler={fnChangeHandler}/> kg
                                            </td>
                                        </tr>
                                        <tr>
                                            <th className={"App-TableBlockCellLabel"}>
                                                Lang:
                                            </th>
                                            <td className={"App-TableBlockCellField"}>
                                                <InputText name={"lang"} isEditable={isEditable}
                                                           width={"calc(100% - 20px)"} value={showGirl.lang}
                                                           fnChangeHandler={fnChangeHandler}/>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th className={"App-TableBlockCellLabel"}>
                                                Hair:
                                            </th>
                                            <td className={"App-TableBlockCellField"}>
                                                <InputText name={"hair"} isEditable={isEditable}
                                                           width={"calc(100% - 20px)"} value={showGirl.hair}
                                                           fnChangeHandler={fnChangeHandler}/>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th className={"App-TableBlockCellLabel"}>
                                                Tits / Intimity:
                                            </th>
                                            <td className={"App-TableBlockCellField"}>
                                                <InputText name={"tits"} isEditable={isEditable}
                                                           width={"calc(50% - 10px)"} value={showGirl.tits}
                                                           fnChangeHandler={fnChangeHandler}/>
                                                <InputText name={"intimity"} isEditable={isEditable}
                                                           width={"calc(50% - 10px)"} value={showGirl.intimity}
                                                           fnChangeHandler={fnChangeHandler}/>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th className={"App-TableBlockCellLabel"}>
                                                Girl Info:
                                            </th>
                                            <td className={"App-TableBlockCellField"}>
                                                <InputTextarea name={"girlInfo"} isEditable={isEditable}
                                                               width={"calc(100% - 20px)"}
                                                               value={showGirl.girlInfo}
                                                               fnChangeHandler={fnChangeHandler} rows={3}/>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th className={"App-TableBlockCellLabel"}>
                                                Service Info:
                                            </th>
                                            <td className={"App-TableBlockCellField"}>
                                                <InputTextarea name={"serviceInfo"} isEditable={isEditable}
                                                               width={"calc(100% - 20px)"}
                                                               value={showGirl.serviceInfo}
                                                               fnChangeHandler={fnChangeHandler} rows={3}/>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th className={"App-TableBlockCellLabel"}>
                                                Tattoo(s):
                                            </th>
                                            <td className={"App-TableBlockCellField"}>
                                                <InputTextarea name={"girlTattoo"} isEditable={isEditable}
                                                               width={"calc(100% - 20px)"}
                                                               value={showGirl.girlTattoo}
                                                               fnChangeHandler={fnChangeHandler} rows={3}/>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th className={"App-TableBlockCellLabel"}>
                                                # Had Sex:
                                            </th>
                                            <td className={"App-TableBlockCellField"}>
                                                <InputNumber name={"numHadSex"} isEditable={isEditable}
                                                             value={showGirl.numHadSex} width={"30%"}
                                                             fnChangeHandler={fnChangeHandler}/>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th className={"App-TableBlockCellLabel"}>
                                                Image URL / File:
                                            </th>
                                            <td className={"App-TableBlockCellField"}>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th className={"App-TableBlockCellLabel"}>
                                                Body Rating:
                                            </th>
                                            <td className={"App-TableBlockCellField"}>
                                                <NineRating name={"ratingBody"} value={showGirl.ratingBody}
                                                            isEditable={isEditable} fnChangeHandler={fnChangeHandler}/>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th className={"App-TableBlockCellLabel"}>
                                                Face Rating:
                                            </th>
                                            <td className={"App-TableBlockCellField"}>
                                                <NineRating name={"ratingFace"} value={showGirl.ratingFace}
                                                            isEditable={isEditable} fnChangeHandler={fnChangeHandler}/>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th className={"App-TableBlockCellLabel"}>
                                                Service Rating:
                                            </th>
                                            <td className={"App-TableBlockCellField"}>
                                                <NineRating name={"ratingServices"} value={showGirl.ratingServices}
                                                            isEditable={isEditable} fnChangeHandler={fnChangeHandler}/>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th className={"App-TableBlockCellLabel"}>
                                                Rating Comment:
                                            </th>
                                            <td className={"App-TableBlockCellField"}>
                                                <InputTextarea name={"ratingComment"} isEditable={isEditable}
                                                               width={"calc(100% - 20px)"}
                                                               value={showGirl.ratingComment}
                                                               fnChangeHandler={fnChangeHandler} rows={3}/>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th className={"App-TableBlockCellLabel"}>
                                                1st Act-/Last Deactivation:
                                            </th>
                                            <td className={"App-TableBlockCellField"}>
                                                <InputText name={"firstActivationYear"} isEditable={isEditable}
                                                           value={firstActivationYearText} width={"30%"}
                                                           fnChangeHandler={fnChangeHandler}/>
                                                <InputText name={"lastInactivationYear"} isEditable={false}
                                                           value={lastInactivationYearText} width={"30%"}
                                                           fnChangeHandler={fnChangeHandler}/>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <br/>
                                </div>
                                <br/>
                                <div className={clubStyles.divBlock}>
                                    <div className={clubStyles.divBlockTitle}>
                                        Girl working in Clubs:
                                    </div>
                                    <table width={"100%"}>
                                        <colgroup>
                                            <col width={"30px"}/>
                                            <col width={"30px"}/>
                                            <col width={"50%"}/>
                                            <col width={"50%"}/>
                                            <col width={"30px"}/>
                                        </colgroup>
                                        <tbody>
                                        <tr>
                                            <th>
                                                Home
                                            </th>
                                            <th>
                                                Act.
                                            </th>
                                            <th>
                                                Club Name
                                            </th>
                                            <th>
                                                Girl Reference
                                            </th>
                                            <th>
                                                <img src={require("../../../../img/addBlack_24px.png")} alt={"+"} onClick={this.selectAddClubMap} title={"Add new working club item"} style={{cursor: "pointer"}} />
                                            </th>
                                        </tr>
                                        {showGirl.girlClubList.map((gcItem) => {
                                            return (
                                                <tr key={"trClub_" + gcItem.girlClubMapId} onClick={() => this.selectGirlClubMap(gcItem)}>
                                                    <td>
                                                        {gcItem.clubIsHome === 1 &&
                                                            <img
                                                                src={require("../../img/girlIsHome_white_16px.png")}
                                                                alt={"Home"}/>
                                                        }
                                                    </td>
                                                    <td>
                                                        {gcItem.isActive === 1 &&
                                                            <img
                                                                src={require("../../img/isActive_16px.png")}
                                                                alt={"Home"}/>
                                                        }
                                                    </td>
                                                    <td>
                                                        {gcItem.clubName}
                                                    </td>
                                                    <td>
                                                        {gcItem.girlRef}
                                                    </td>
                                                    <td>
                    
                                                    </td>
                
                                                </tr>
                                            )
                                        })}
        
                                        </tbody>
                                    </table>
                                </div>

                            </td>
                        </tr>
                        </tbody>
                    </table>
                    {askForDeleteGirl &&
                            <ConfirmDialog
                                fnClose={this.callbackAskForDeleteGirl}
                                confirmTitle={"Sicherheitsabfrage"}
                                confirmText={"Wollen Sie dieses Girl '" + showGirl.girlName + "' wirklich löschen?"}
                                confirmHint={"Das Girl wird komplett mit allen Club-Beziehungen gelöscht. Dieser Vorgang kann nicht rückgängig gemacht werden."}
                            />
                    }
                    {askForDeleteImage &&
                        <ConfirmDialog
                            fnClose={this.callbackAskForDeleteImage}
                            confirmTitle={"Sicherheitsabfrage"}
                            confirmText={"Wollen Sie dieses File '" + showImg + "' wirklich löschen?"}
                            confirmHint={"Das File wird komplett gelöscht. Dieser Vorgang kann nicht rückgängig gemacht werden."}
                        />
                    }
                    {selectedGirlClub &&
                        <EditGirlClubMap showGirlClubMap={selectedGirlClub} fnClose={this.closeEditGirlClubMap} allCurrentClubMap={showGirl.girlClubList} />
                    }
                    {isShowFotoNavigator === true &&
                        <SharedBigFotoNavigator fotoList={transformedFileList} selectedFotoIdx={currentFotoIdx} fnModalClose={() => this.setState({isShowFotoNavigator: false})} />
                    }
                </>
            )
        } else {
            return null;
        }
    }
}

export const ClubGirlDynDetailInfo = connect(mapStateToProps, appDataMapDispatchToProps)(ClubGirlDynDetailInfoComponent);
