import React from 'react';
import {Form, Button, Modal} from "react-bootstrap";
import {IClubItems, IClubItem, IGirlClubMapItem, IGirlClubMapItems} from "../../typeDef/clubData";
import clubStyles from "./club.module.css";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import {InputText} from "../../../../component/input/InputText";
import {ServiceCall} from "../../../../sharedLib/ServiceCall";
import sl6ServicePath from "../../config/sl6Services.json";
import {IApiFeedback} from "../../../shared/typeDef/apiFeedback";
import {IMessageList} from "../../../../typeDef/messageList";
import {MessageList} from "../../../../component/messageList/MessageList";
import {appDataMapDispatchToProps} from "../../../../redux/appDataMapDispatchToProps";
import {IRootState} from "../../../../redux";
import {connect} from "react-redux";
import {ClubGirlDynDetailInfoComponent} from "./ClubGirDynDetailInfo";
import {ConfirmDialog} from "../../../../component/customModalDialog/ConfirmDialog";

interface ICompProps {
    showGirlClubMap?: IGirlClubMapItem;
    fnClose: (changed: boolean) => void;
    allCurrentClubMap?: IGirlClubMapItems;
}
interface IState {
    girlClubMapData: IGirlClubMapItem;
    clubListing: IClubItems,
    apiClubListingFeedback: IApiFeedback
    msgList?: IMessageList;
    askForDelete?: boolean;
}

const mapStateToProps = (state: IRootState) => {
    return {
        adSl6Girl: state.appData.sl6Girl
    }
}

type DispatchProps = ReturnType<typeof appDataMapDispatchToProps>;
type StateProps = ReturnType<typeof mapStateToProps>;
type AllProps = DispatchProps & StateProps & ICompProps;

const emptyClubList: IClubItems = [];
const emptyMsgList: IMessageList = [];

export class EditGirlClubMapComponent extends React.Component<AllProps, IState> {
    public state = {
        girlClubMapData: this.props.showGirlClubMap,
        clubListing: emptyClubList,
        apiClubListingFeedback: {},
        msgList: emptyMsgList,
        askForDelete: false
    }
    
    componentDidMount = () => {
        this.loadAllClubListing()
    }
    
    private callbackLoadAllClubListing = (serviceCall: ServiceCall) => {
        const apiClubListingFeedback = {
            state: 'loaded',
            error: serviceCall.getRequestConfigStatusCode() === 0 ? '' : 'Warning/Error',
            msgList: serviceCall.getResponseInfo(),
        }
        // neu mit Redux Daten laden
        this.setState({apiClubListingFeedback, clubListing: serviceCall.getResponseData()});
    }
    
    loadAllClubListing = () => {
        const clubListingService = new ServiceCall(sl6ServicePath.clubListing);
        clubListingService.executePost({}, this.callbackLoadAllClubListing);
    }
    
    private setSwitchValue = (name: string, value: boolean) => {
        const stateGirlClubMapData: any = Object.assign({}, this.state.girlClubMapData);
        stateGirlClubMapData[name] = value === false ? 0 : 1;
        if (!stateGirlClubMapData.wsMode || stateGirlClubMapData.wsMode === "") {
            stateGirlClubMapData.wsMode = 'U';
        }
        this.setState({girlClubMapData: stateGirlClubMapData});
    }
    
    private changeHandler = (action: string, name?: string, newValue?: any): void => {
        const stateGirlClubMapData: any = Object.assign({}, this.state.girlClubMapData);
        stateGirlClubMapData[name] = newValue;
        this.setState({girlClubMapData: stateGirlClubMapData});
    }
    private selectNewClub = (evt: any): void => {
        const {clubListing} = this.state;
        const girlClubMapData = Object.assign({}, this.state.girlClubMapData);
        const clubId = parseInt(evt.target.value);
        const selectedClub = clubListing.find((clubItem: IClubItem) => clubItem.clubId === clubId);
        if (selectedClub) {
            girlClubMapData.clubId = clubId;
            girlClubMapData.clubName = selectedClub.clubName;
            this.setState({girlClubMapData});
        }
        
    }
    
    private activateEditMode = (): void => {
        const girlClubMapData = Object.assign({}, this.state.girlClubMapData);
        if (!girlClubMapData || girlClubMapData.wsMode === "") {
            girlClubMapData.wsMode = "U";
            this.setState({girlClubMapData});
        }
    }
    
    private cancelAction = (): void => {
        const {girlClubMapData} = this.state;
        const {fnClose} = this.props;
        if (girlClubMapData.wsMode === "" || girlClubMapData.wsMode === "A") {
            fnClose(false);
        } else if (girlClubMapData.wsMode === "U") {
            girlClubMapData.wsMode = "";
            this.setState({girlClubMapData: this.props.showGirlClubMap}); // incl. Reset old Values from Props
        }
    }
    
    private callbackSaveData = (sc: ServiceCall): void => {
        const {fnClose} = this.props;
        let msgList = emptyMsgList;
        this.setState ({askForDelete: false, msgList});
        if (sc.getRequestConfigStatusCode() === 0) {
            const wsMode = sc.getRequestParam("wsMode");
            const data = sc.getResponseData();
            this.props.updateDataItem("sl6Girl", Object.assign({}, data), "girlId");
            fnClose(true);
        } else {
            msgList = sc.getResponseInfo();
            this.setState({msgList})
        }
    }
    
    private saveData = (): void => {
        const {girlClubMapData} = this.state;
        console.log("saveData:");
        console.log(girlClubMapData);
        const msgList: IMessageList = emptyMsgList;
        if (!girlClubMapData.clubId || girlClubMapData.clubId === 0) {
            msgList.push({msgNo: 1, msgType: "error", msgText: "You have to define a Club where the girl is working. Field is mandatory"});
            this.setState({msgList});
        }
        if (msgList.length === 0) {
            const sc = new ServiceCall(sl6ServicePath.saveGirlClubMapDetails);
            sc.setRequestParam("wsMode", girlClubMapData.wsMode);
            sc.executePost(girlClubMapData, this.callbackSaveData);
        }
    }
    
    private callbackAskForDelete = (action: string): void => {
        if (action === "yes") {
            const {girlClubMapData} = this.state;
            girlClubMapData.wsMode = "D";
            const sc = new ServiceCall(sl6ServicePath.saveGirlClubMapDetails);
            sc.setRequestParam("wsMode", girlClubMapData.wsMode);
            sc.executePost(girlClubMapData, this.callbackSaveData);
        } else {
            this.setState ({askForDelete: false});
        }
    }
    
    render() {
        const { girlClubMapData, clubListing, msgList, askForDelete } = this.state;
        const { fnClose, allCurrentClubMap } = this.props;
        let formTitle: string = "Working in Club Details";
        if (girlClubMapData.wsMode === "A") {
            formTitle = "Add new Working in Club Data";
        } else if (girlClubMapData.wsMode === "U") {
            formTitle = "Update current Working in Club Data"
        }
        const isEditable: boolean = (girlClubMapData.wsMode === "A" || girlClubMapData.wsMode === "U");
        const isDisabled: boolean = !isEditable;
        
        return (
            <>
                <Modal show={true}  size={"lg"} onHide={() => fnClose(false)} keyboard={false}>
                    <Modal.Header closeButton={true}>
                        <Modal.Title>
                            {formTitle}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <MessageList msgList={msgList} />
                        <table className={clubStyles.tableGirlClubMap}>
                            <tbody>
                            <tr>
                                <td className={clubStyles.tdGirlClubMapFieldCell} width={"25%"}>
                                    Club:
                                </td>
                                <td className={"App-TableBlockCellField"} width={"75%"}>
                                    {girlClubMapData.wsMode === "A" &&
                                        <>
                                            <Form.Select aria-label="Club Listing" onChange={this.selectNewClub} value={"" + girlClubMapData.clubId}>
                                                <option>Please select a Club</option>
                                                {clubListing.map((clubItem: IClubItem) => {
                                                    // show only Clubs where no clubMap Item already exisist
                                                    if (allCurrentClubMap.findIndex((accm: IGirlClubMapItem) => accm.clubId === clubItem.clubId) < 0) {
                                                        return (
                                                            <option key={"clubListingOption_" + clubItem.clubId} value={clubItem.clubId}>{clubItem.clubName}</option>
                                                        )
                                                    } else {
                                                        return null;
                                                    }
                                        
                                                })
                                                }
                                            </Form.Select>
                                        </>
                                    }
                                    {girlClubMapData.wsMode !== "A" &&
                                        <>
                                            {girlClubMapData.clubName}
                                        </>
                                    }
                    
                                </td>
                            </tr>
                            <tr>
                                <td className={clubStyles.tdGirlClubMapFieldCell}>
                                    Home:
                                </td>
                                <td className={"App-TableBlockCellField"}>
                                    <BootstrapSwitchButton
                                        checked={girlClubMapData.clubIsHome === 1}
                                        onlabel='Home'
                                        offlabel='Guest'
                                        width={200}
                                        onstyle="primary"
                                        onChange={(value) => this.setSwitchValue("clubIsHome", value)}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td className={clubStyles.tdGirlClubMapFieldCell}>
                                    Activ:
                                </td>
                                <td className={"App-TableBlockCellField"}>
                                    <BootstrapSwitchButton
                                        checked={girlClubMapData.isActive === 1}
                                        onlabel='Active'
                                        offlabel='Inactive'
                                        width={200}
                                        onstyle="primary"
                                        onChange={(value) => this.setSwitchValue("isActive", value)}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td className={clubStyles.tdGirlClubMapFieldCell}>
                                    Girl-Reference:
                                </td>
                                <td className={"App-TableBlockCellField"}>
                                    <InputText name={"girlRef"} isEditable={isEditable}
                                               width={"calc(100% - 20px)"} value={girlClubMapData.girlRef}
                                               fnChangeHandler={this.changeHandler} fnMouseClick={this.activateEditMode} />
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </Modal.Body>
                    <Modal.Footer>
                        {(girlClubMapData.wsMode === "A" || girlClubMapData.wsMode === "U") &&
                            <React.Fragment>
                                <Button variant={"primary"} onClick={this.saveData}>Save</Button>
                                <Button variant={"secondary"} onClick={this.cancelAction}>Cancel</Button>
                            </React.Fragment>
                        }
                        {girlClubMapData.wsMode === "" &&
                            <>
                                <Button variant={"secondary"} onClick={this.activateEditMode}>Edit</Button>
                                <Button variant={"secondary"} onClick={() => this.setState({askForDelete: true})}>Delete</Button>
                                <Button variant={"secondary"} onClick={this.cancelAction}>Cancel</Button>
                            </>
                        }
                    </Modal.Footer>
                </Modal>

                {askForDelete &&
                <ConfirmDialog
                    fnClose={this.callbackAskForDelete}
                    confirmTitle={"Sicherheitsabfrage"}
                    confirmText={"Wollen Sie dieses Girl-Mapping '" + girlClubMapData.clubName + "' wirklich löschen?"}
                    confirmHint={"Dieser Vorgang kann nicht rückgängig gemacht werden."}
                />
                }
            </>
        )
        
    }
}
export const EditGirlClubMap = connect(mapStateToProps, appDataMapDispatchToProps)(EditGirlClubMapComponent);