import React, {useEffect, useState} from 'react';


export const GameHistory: React.FC<{
}> = ({
}) => {
    // state
//    const [apiPornStars, setApiPornStars] = useState<IApiFeedback>();
    
    // use-effect
//    useEffect(() => {
//        loadAllPornstarListing();
//    }, []);
    
    // functions
    
    // other
    
    
    return (
        <>
            {/*
                -- Brot-Krümel: Titel-Navigation ----------------------------------------------------------------------
*/}
            <div className={"titleNavLineBlock"}>
                Game History
            </div>
        
        
        
        </>
    )
}

