import * as React from "react";
// import { StrictMode } from "react";

import {
    configureStore,
    createImmutableStateInvariantMiddleware,
} from '@reduxjs/toolkit'

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {PageHeader} from "./client/start/PageHeader";
import {BrowserRouter} from "react-router-dom";
import {AppRouting} from "./client/start/AppRouting";
import sharedServices from "./config/sharedServices.json";
import {ServiceCall} from "./client/sharedLib/ServiceCall";
import allReducers from "./client/redux";
import { Provider } from "react-redux";

const immutableInvariantMiddleware = createImmutableStateInvariantMiddleware({
    ignoredPaths: ['ignoredPath', 'ignoredNested.one', 'ignoredNested.two'],
})

let store = configureStore({
    reducer: allReducers,
    middleware: [immutableInvariantMiddleware],
})


// DISPATCH

// store.dispatch(increment());

export default class App extends React.Component<{}, {}> {
    
    public componentDidMount() {
        const initService = new ServiceCall(sharedServices.init);
        initService.executePost({}, this.callbackInit);
    }
    
    private callbackInit = (serviceCall: any): void => {
        const statusCode = serviceCall.getRequestConfigStatusCode();
        const data = serviceCall.getResponseData();
    }
    
    render() {
        return (
                <Provider store={store} >
                    <BrowserRouter>
                        <div className="App">
                            <PageHeader/>
                            <AppRouting/>
                        </div>
                    </BrowserRouter>
                </Provider>
        );
        
    }
}

