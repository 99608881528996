import React, {useEffect, useState} from 'react';
//import PropTypes from 'prop-types';


export const GameChart: React.FC<{
}> = ({
}) => {
    // state
//    const [apiPornStars, setApiPornStars] = useState<IApiFeedback>();
    
    // use-effect
//    useEffect(() => {
//        loadAllPornstarListing();
//    }, []);
    
    // functions
    
    // other
    
    
    return (
        <>
            {/*
                -- Brot-Krümel: Titel-Navigation ----------------------------------------------------------------------
*/}
            <div className={"titleNavLineBlock"}>
                Game Chart
            </div>
        
        
        
        </>
    )
}

