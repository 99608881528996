export function formatNumberToString (inNumber: number, numDec: number): string {
    let outString: string = "";
    
    if (inNumber !== null) {
        const intNumber = Math.trunc(inNumber);
        const intNumberString: string = intNumber.toString();
        const numberArray = intNumberString.split("");
        
        for (let pos = 0; pos < intNumberString.length ; pos++) {
            const numLeft = intNumberString.length - (pos + 1);
            outString = outString + numberArray[pos];
            if ((numLeft) % 3 === 0 && numLeft > 0) {
                outString = outString + "'";
            }
        }
        if (numDec > 0) {
            const fixedNumber = inNumber.toFixed(numDec);
            const decStringArray = fixedNumber.split(".");
            const decString = decStringArray[decStringArray.length];
            outString = outString + "." + decString;
        }
    }
    return outString;
}