import React from 'react';
import {Button, Dropdown} from "react-bootstrap";
import {getNewGirlObject} from "./newDynGirlLib";
import clubStyles from "./club.module.css";
import appGenericStyles from "../../../../css/appGeneric.module.css";
import {getlocalHtdocsPath} from "../../config/getConfigData";
import {getFirstImageFromFileList} from "../../../../sharedLib/fileLib";
import {InputText} from "../../../../component/input/InputText";
import {IClubItem} from "../../typeDef/clubData";
import {IGirlItem, IGirlItems} from "../../typeDef/girlData";
import {NatListComponent} from "../../../../component/natListComponent/NatListComponent";
import {LoadingInfo} from "../../../../component/loadingInfo/LoadingInfo";

interface IProps {
    selectedClub?: IClubItem;
    fnSelectGirl: (g: IGirlItem) => void;
    fnAddNewGirl: (girl: IGirlItem) => void;
    filteredData: IGirlItems;
    girlSearchField: string;
    fnGirlSearchChangeHandler: (value: string) => void;
    fnMarkGirlAsControlled: (g: IGirlItem) => void;
    clubGirlsControlledIds: number[];
    loadState: string;
}

interface IState {
    tabKey: string;
    searchActivitySetting: string;
}

export class ClubGirlDynListNormal extends React.Component<IProps, IState> {
    
    public state = {
        tabKey: 'active',
        searchActivitySetting: 'all'
        
    }

    private setTabKey = (key: string) => {
        this.setState({tabKey: key});
    }

    private markAsControlled = (e: any, girl: IGirlItem) => {
        const { fnMarkGirlAsControlled } = this.props;
        e.stopPropagation();
        fnMarkGirlAsControlled(girl);
    }

    private onChange = (what: any, name: string, value: string) => {
        if (name === "searchGirlName") {
            this.props.fnGirlSearchChangeHandler(value);
        }
    }

    onSelectActivitySetting = (value: string) => {
        this.setState({searchActivitySetting: value});
    }
    
    addNewGirl = (): void => {
        const { selectedClub, fnAddNewGirl } = this.props;
        const newGirl = getNewGirlObject(selectedClub.clubId);
        fnAddNewGirl(newGirl);
//        fnSelectGirl(newGirl);
    }
    
    render() {
        const {selectedClub, fnSelectGirl, fnMarkGirlAsControlled, filteredData, clubGirlsControlledIds, girlSearchField, loadState} = this.props;
        const { searchActivitySetting } = this.state;
        const localHtDocsPath = getlocalHtdocsPath();
        if (selectedClub && filteredData) {
            let newFilteredData = filteredData;
            if (girlSearchField !== null && girlSearchField !== '') {
                newFilteredData = filteredData.filter((item) => item.girlName.toLowerCase().includes(girlSearchField.toLowerCase()));
            }
            const sortedGirlList = newFilteredData.sort((a, b) => {
                if (a.girlName < b.girlName) return -1;
                if (a.girlName > b.girlName) return 1;
                return 0;
            })
            
            if (loadState === "pending") {
                return (
                    <>
                        <LoadingInfo />
                    </>
                )
            }

            return (
                <>
                    <br />
                    <div className={appGenericStyles.divTableNoWidth}>
                        <div className={appGenericStyles.divTableRow}>
                            <div className={appGenericStyles.divTableCell}>
                                Girl Name: <InputText name={"searchGirlName"} value={girlSearchField} fnChangeHandler={this.onChange} width={"200px"} isEditable={true} />
                            </div>
                            <div className={appGenericStyles.divTableCell}>
                                <Dropdown className={"none"} style={{width: "200px"}}>
                                    <Dropdown.Toggle variant="secondary" id="dropdown-basic" size={"sm"}>
                                        Activity: {searchActivitySetting} &nbsp;
                                    </Dropdown.Toggle>
            
                                    <Dropdown.Menu>
                                        <Dropdown.Item onClick={() => this.onSelectActivitySetting("all")}>show all</Dropdown.Item>
                                        <Dropdown.Item onClick={() => this.onSelectActivitySetting("active")}>active only</Dropdown.Item>
                                        <Dropdown.Item onClick={() => this.onSelectActivitySetting("inactive")}>inactive only</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                            <div className={appGenericStyles.divTableCell}>
                                <Button variant={"secondary"} size={"sm"} onClick={this.addNewGirl}>Add girl</Button>
                            </div>
                            <div className={appGenericStyles.divTableCell}>
                                Total: {sortedGirlList.length} Entries
                            </div>
                        </div>
                    </div>
                    <div className={"newLine"}>
                        <ul key={"clsb_ul"} className={clubStyles.clubGirlListUl}>
                            {sortedGirlList.map((girl, girlIdx) => {
                                if (clubGirlsControlledIds.findIndex((item) => item === girl.girlId) < 0) {
                                    let isActive: boolean = false;
                                    let hasNoLink: boolean = true;
                                    girl.girlClubList.forEach((gclItem) => {
                                        // check Infos from selected Club-Mapping
                                        if (gclItem.clubId === selectedClub.clubId) {
                                            if (gclItem.isActive === 1) {
                                                isActive = true;
                                            }
                                            if (gclItem.girlRef && gclItem.girlRef?.length > 0 ) {
                                                hasNoLink = false;
                                            }
                                        }
                                    })
                                    const imgFile = getFirstImageFromFileList(girl.fileItems);
                                    const cssGirlLabel = (isActive ? clubStyles.clubGirlListLabelActive : clubStyles.clubGirlListLabelInactive);
                                    const imgPath = imgFile ?  localHtDocsPath + "girl/girl_" + girl.girlId + "/" + imgFile.filename : "";
                                    if (searchActivitySetting === "all" || (searchActivitySetting === "active" && isActive !== false) || (searchActivitySetting === "inactive" && isActive === false)) {
                                        return (
                                            <React.Fragment key={"rf_" + girl.girlId}>
                                                <li key={"li_" + girlIdx} onClick={() => fnSelectGirl(girl)}>
                                                    <div key={"li_div_1_" + girl.girlId}
                                                         className={clubStyles.clubGirlListDiv}>
                                                        {imgPath !== "" &&
                                                            <img className={clubStyles.clubGirlListImg}
                                                                 src={imgPath} alt={""}/>
                                                        }
                                                        <img className={clubStyles.controlledButton}
                                                             src={require("../../img/check_24.png")} alt={"Button"}
                                                             onClick={(e) => this.markAsControlled(e, girl)}/>
                                                    </div>
                                                    <div key={"li_div_2_" + girl.girlId} className={cssGirlLabel}>
                                                        {girl.girlName} - {girl.age}
                                                        <br/>
                                                        <NatListComponent natString={girl.nat}/>
                                                        {hasNoLink === true &&
                                                            <img src={require("../../img/noLink_24px.png")} alt={"No Girl Link"} />
                                                        }
{/*
                                                        {girl.isFav === 1 &&
                                                            <img src={"/img/symbol/favorite_star_icon_16.png"}
                                                                 alt={"Favorite"}
                                                                 className={clubStyles.clubGirlListLabelImg}/>
                                                        }
                                                        {girl.hadSex === 1 &&
                                                            <img src={"/img/symbol/check_ok_yes_icon_16.png"}
                                                                 alt={"hadSex"}
                                                                 className={clubStyles.clubGirlListLabelImg}/>
                                                        }
*/}
                                                    </div>
                                                </li>

                                            </React.Fragment>
                                        )
                                    }
                                }
                                return null;
                            })}
                            {/*
                            <ClubListingAddNewBlock girl={newGirl} fnSelectGirl={fnSelectGirl} />
    */}
                        </ul>
                    </div>
                    <br/>
                    {clubGirlsControlledIds.length > 0 &&
                        <Button onClick={() => fnMarkGirlAsControlled(null)}>reset Control List</Button>
                    }
                </>
            )
        } else {
            return null;
        }
    }
}
