const currentUser = (state = {}, action: any) => {
//    for Debugging just remove the comments on the following line
//    console.log("userReducer:");
//    console.log("- action.type: "+ action.type);
//    console.log(action);
    switch (action.type) {
        case "USER_LOGIN":
            return {
                ...state, user: action.payload
            }
        case "USER_LOGOUT":
            return {
                ...state, user: {}
            }
/*
        case "AD_SETDATAFULL":
                return {
                ...state, userData: action.payload
            }
*/
        default:
            return state;
    }
}

export default currentUser;