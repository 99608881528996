import {IFileItem, IFileItems} from "../typeDef/fileItem";

const imageEndingList = ["jpg", "jpeg", "gif", "png", "tif", "tiff", "bmp", "jfif", "avif"];
const videoEndingList = ["mp4", "mov"];
const serverRootDir: string =  "http://localhost:80/htdocs/";

export function getFirstImageFromFileList (fileList: IFileItems): IFileItem {
    let firstImage: IFileItem = null;
    if (fileList) {
        fileList.forEach((file) => {
            const listPos = imageEndingList.findIndex((end) => end === file.ending.toLowerCase());
            if (listPos >= 0 && firstImage === null) {
                firstImage = file;
            }
        });
    }
    return firstImage;
}

export function getFileType (fileItem: IFileItem): string {
    let retFileType: string = "?";
    
    const ending = fileItem.ending ? fileItem.ending.toLowerCase() : "";
    
    if (imageEndingList.includes(ending)) {
        retFileType = "image";
    } else if (videoEndingList.includes(ending)) {
        retFileType = "video";
    }
    return retFileType;
}

export function getFileTypeFromFileName (fileName: string): string {
    let retFileType: string = "?";
    let ending: string = "";
    if (fileName && fileName !== "") {
        const splitFileName: string[] = fileName.split(".");
        ending = splitFileName[splitFileName.length - 1].toLowerCase();
    }
    retFileType = getFileType({filename: fileName, dir: "", ending, isDir: false, })
    return retFileType;
}

export function checkFileTypeIsImage(fileItem: IFileItem): boolean {
    const ending = fileItem.ending.toLowerCase();
    return imageEndingList.includes(ending);
}
export function checkFileTypeIsVideo(fileItem: IFileItem): boolean {
    const ending = fileItem.ending.toLowerCase();
    return videoEndingList.includes(ending);
}

