import axios from "axios";

import {IMessage, IMessageList} from "../typeDef/messageList";
import {IApiFeedback} from "../app/shared/typeDef/apiFeedback";

interface IRequestConfig {
    requestUrl: string;
    requestBody: any;
    requestStateCode: number;
    requestStateText: string;
    requestOnCompleteCallback: any;
}
interface IRequestParam {
    name?: string;
    value?: any;
}
let msgNr = 0;
const serviceUrlBaseLocal = "http://localhost/api/";
const serviceUrlBaseServer = "/api/";
//const serviceUrlBase = "/api/";
// const emptyArray: any = [];

export class ServiceCall {
    private requestConfig: IRequestConfig;
    private responseData: any;
    private responseInfo: IMessageList;
    private requestParam: IRequestParam[];
    
    constructor(serviceUrl: string) {
        this.requestConfig = {
            requestUrl: serviceUrl,
            requestBody: null,
            requestStateCode: -1,
            requestStateText: "Initiated",
            requestOnCompleteCallback: null,
        }
        this.responseData = null;
        this.responseInfo = [];
        this.requestParam = [];
    }
    
    public getRequestConfig() {
        return this.requestConfig;
    }
    public getResponseData() {
        return this.responseData;
    }
    public getResponseInfo() {
        return this.responseInfo;
    }
    public getRequestConfigStatusCode() {
        return this.requestConfig.requestStateCode;
    }
    public getRequestParam(name: string): any {
        let retValue = null;
        const retParam: IRequestParam =  this.requestParam.find((rp) => rp.name === name);
        if (retParam) {
            retValue = retParam.value;
        }
        return retValue;
    }

    public setRequestParam(name: string, value: any): void {
        const rpParam = this.requestParam.find((rp) => rp.name === name);
        if (rpParam) {
            rpParam.value = value;
        } else {
            this.requestParam.push({name, value});
        }
    }
    
    private getServiceUrlBase = (): string => {
        const hostname = window.location.hostname;
        if (hostname === "localhost") {
            return serviceUrlBaseLocal;
        }
        return serviceUrlBaseServer
    }
    
    public getApiFeedback = (): IApiFeedback => {
        return {
            state: 'loaded',
            error: this.getRequestConfigStatusCode() === 0 ? '' : 'Warning/Error',
            data: this.getResponseData(),
            msgList: this.getResponseInfo(),
        }
        
    }

    public executePost(data: any, onComplete: (sc: ServiceCall) => void) {
        const serviceUrlBase = this.getServiceUrlBase();
        this.requestConfig.requestBody = data;
        this.requestConfig.requestOnCompleteCallback = onComplete;
        axios({
            method: 'post',
            url: serviceUrlBase + this.requestConfig.requestUrl + ".php",
            headers: {
                'content-type': 'application/json'
            },
            data: data,
        })
            .then(result => {
                if(result && result.data) {
                    const resultData = result.data;
                    if (resultData.reqInfo && resultData.reqInfo.statusCode !== null) {
                        const reqInfo = resultData.reqInfo;
                        this.requestConfig.requestStateCode = reqInfo.statusCode;
                        this.requestConfig.requestStateText = reqInfo.statusText;
                    } else {
                        this.requestConfig.requestStateCode = 98;
                        this.requestConfig.requestStateText = "Result not in expected structure (1)";
                        msgNr++;
                        this.responseInfo.push({
                            msgNo: msgNr,
                            msgType: "error",
                            msgText: "Status-Code: 98 / Result not in expected structure (1).",
                            msgDetails: JSON.stringify(result)
                        })
                    }
                    if (resultData.reqData) {
                        this.responseData = resultData.reqData;
                    }
                    if (resultData.reqMessage) {
                        resultData.reqMessage.forEach((msg: IMessage) => {
                            msgNr++;
                            this.responseInfo.push({
                                msgNo: msgNr,
                                msgType: msg.msgType,
                                msgText: msg.msgText,
                                msgDetails: msg.msgDetails
                            });
                        });
                    }
                } else {
                    this.requestConfig.requestStateCode = 98;
                    this.requestConfig.requestStateText = "Result not in expected structure (2)";
                    msgNr++;
                    this.responseInfo.push({
                        msgNo: msgNr,
                        msgType: "error",
                        msgText: "Result not in expected structure (2)",
                        msgDetails: JSON.stringify(result)
                    });
                }
                onComplete(this);
            })
            .catch(error => {
                if (error.messageType !== undefined) {
                    msgNr++;
                    this.requestConfig.requestStateCode = 99;
                    this.requestConfig.requestStateText = "Error Request-Call";
                    this.responseInfo.push({
                        msgNo: msgNr,
                        msgType: "error",
                        msgText: "Error Request-Call",
                        msgDetails: error.message
                    })
                    onComplete(this);
                }
            });
    }
}
