import React, {useEffect, useState} from 'react';
import {Accordion, Badge} from 'react-bootstrap';
import clubStyles from "./club.module.css";


import {ServiceCall} from "../../../../sharedLib/ServiceCall";
import sl6ServicePath from "../../config/sl6Services.json";
import {IClubItem, IClubItems} from "../../typeDef/clubData";

interface IRegion {
    regionName: string;
    regionClubItems: IRegionClubItem[];
}
interface IRegionClubItem {
    townName: string,
    clubItem: IClubItem
}


export const ClubDynMap: React.FC<{
    clubDataList?: IClubItems;
    fnSelectClub: (evt: any, c: IClubItem, withLoad: boolean) => void;
    
}> = ({
          clubDataList, fnSelectClub
      }
) => {
    
    // state
    
    // use-effect
    
    // functions
    
    // other
    const regionList: IRegion[] = [];
    if (clubDataList && clubDataList.length > 0) {
        clubDataList.forEach((club) => {
            let clubRegionsSplit = club.locTown?.split("\n");
            if (!clubRegionsSplit || clubRegionsSplit.length === 0) {
                clubRegionsSplit = ["Undefined"];
            }
            clubRegionsSplit.forEach((clubRegion) => {
                const regionItemSplit = clubRegion.split("|");
                if (regionItemSplit) {
                    const regionName: string = regionItemSplit[0].replace("\r", "").trimRight();
                    const regionTown: string = regionItemSplit.length > 1 ? regionItemSplit[1].replace("\r", "").trimRight() : "";
                    let regionIdx = regionList.findIndex((regionItem) => regionItem.regionName === regionName);
                    if (regionIdx < 0) {
                        regionList.push({
                            regionName: regionName,
                            regionClubItems: []
                        })
                        regionIdx = regionList.length - 1;
                    }
                    regionList[regionIdx].regionClubItems.push({townName: regionTown, clubItem: club});
                }
            })
        })
    }
    
    const sortedRegionList = regionList.sort((a, b) => a.regionName > b.regionName ? 1 : -1);
    
    return (
        <>
            <br />
            <Accordion>
            {sortedRegionList.map((regionItem) => {
                // const mapImage = regionItem?.regionName?.replace(" ", "").replace("&", "").replace("(", "").replace(")", "");
                const mapImage = regionItem?.regionName?.replace(/[&\/\\#,+()$~%.'":*?<>{}\ ]/g,'');
                return (
                    <>
                            <Accordion.Item eventKey={regionItem.regionName}>
                                <Accordion.Header>
                                <table width={"100%"}>
                                    <tbody>
                                    <tr>
                                        <td width={"80%"}>
                                            <b>{regionItem.regionName}</b>
                                        </td>
                                        <td width={"20%"}>
                                            <Badge bg={"secondary"}>{regionItem.regionClubItems.length}</Badge>
                                        </td>
                                    </tr>
                                    </tbody>
                                    
                                </table></Accordion.Header>
                                <Accordion.Body>
                                    <table width={"100%"}>
                                        <tbody>
                                        <tr>
                                            <td width={"50%"} className={clubStyles.tdTopLeft}>
                                                <table width={"100%"}>
                                                    <colgroup>
                                                        <col width={"70%"} />
                                                        <col width={"30%"} />
                                                    </colgroup>
                                                    <tbody>
                                                    {regionItem.regionClubItems.map((rgItem: IRegionClubItem) => {
                                                        return (
                                                            <tr onClick={(evt: any) => fnSelectClub(evt, rgItem.clubItem, true)} style={{cursor: "pointer"}}>
                                                                <td>
                                                                    <i className={clubStyles.arrowRight}></i>
                                                                    {rgItem.clubItem.clubName}
                                                                </td>
                                                                <td>
                                                                    {rgItem.townName}
                                                                </td>
    
                                                            </tr>
                                                        )
                                                    })}
                                                    </tbody>
                                                </table>
                                            </td>
                                            <td width={"50%"} className={clubStyles.tdTopCenter}>
                                                {mapImage && mapImage !== "-" &&
                                                    <img src={"http://localhost/htdocs/club/clubMap_" + mapImage + ".png"} className={clubStyles.imgClubMap} alt={"Map"} />
                                                }
                                            </td>
                                        </tr>
                                        </tbody>
                                        
                                    </table>
                                </Accordion.Body>
                            </Accordion.Item>
                    </>
                )
            })}
            </Accordion>

        </>
    )
}

