import React from 'react';
import inputStyles from "./input.module.css";
import {formatNumberToString} from "../../sharedLib/numberLib";

interface IProps {
    name: string;
    isEditable: boolean;
    value: number;
    width: string;
    readOnly?: boolean;
    numDec?: number;
    fnChangeHandler: (action: string, name: string, value: any) => void;
}
export class InputNumber extends React.Component<IProps, {}> {

    onChangeHandler = (evt: any) => {
        const { name, isEditable, fnChangeHandler } = this.props;
        
        if (isEditable) {
            const newValue = evt.target.value?.replace("'", "");
            console.log("InputNumber: " + newValue);
            
            fnChangeHandler('change', name, parseInt(newValue));
        }
    }


    render() {
        const { value, name, isEditable } = this.props;
        let numDec = this.props.numDec;
        const readOnly = this.props.readOnly ? this.props.readOnly : false;
        const width = this.props.width || "100%";
        if (!numDec) {
            numDec = 0;
        }
        let showValue: number =  !value ? null : value;
        if (value === undefined) {
            showValue = null;
        }
        const formattedNumber = formatNumberToString(showValue, numDec);
        const styleInputTextInput = (isEditable ? inputStyles.InputTextInputEdit : inputStyles.InputTextInputView);
        if (isEditable) {
            return (
                <input className={styleInputTextInput} name={name} style={{width: width}} type="number" value={showValue} onChange={(evt) => this.onChangeHandler(evt)} readOnly={readOnly} disabled={readOnly} />
                )
        } else {
            return (
                <input className={styleInputTextInput} name={name} style={{width: width}} type="text" value={formattedNumber} onChange={(evt) => this.onChangeHandler(evt)} readOnly={readOnly} disabled={readOnly} />
            )
        }
    }
}
